import "../styles/posts.css";
import image from "../../../assets/images/positive-energy-manager-img.jpg";
import ContactForm from "../../contact-form/contactForm";
import { Helmet } from "react-helmet";

export default function PositiveEnergyManager() {
	return (
		<div className="singlePost">
			<div className="singlePostWrapper">
				<img
					src={image}
					alt="cpositive energy manager"
					className="singlePostImg img-height-500"
				/>
				<h1 className="singlePostTitle">Корпоративно консултиране</h1>
				<Helmet>
					<title>Positive Energy Manager в корпорация</title>
					<meta
						name="description"
						content="Аз съм Positive Energy Manager – професионалист, който комбинира богатия си опит в корпоративния мениджмънт, квалификацията си като енергиен Life Coach и образованието си като психолог, за да създавам и поддържам позитивна, вдъхновяваща и подкрепяща среда за работа. Моите знания и практически умения ми позволяват да имам изострен усет към нуждите на хората в организациите и да предлагам решения, които създават добавена стойност както за служителите, така и за бизнеса."
					/>
				</Helmet>
				<div className="singlePostDesc">
					<p>
						Аз съм Positive Energy Manager – професионалист, който
						комбинира богатия си опит в корпоративния мениджмънт,
						квалификацията си като енергиен Life Coach и
						образованието си като психолог, за да създавам и
						поддържам позитивна, вдъхновяваща и подкрепяща среда за
						работа. Моите знания и практически умения ми позволяват
						да имам изострен усет към нуждите на хората в
						организациите и да предлагам решения, които създават
						добавена стойност както за служителите, така и за
						бизнеса.
					</p>
					<p>
						<b>Основни ангажименти</b>
					</p>
					<p>
						<b>Индивидуални сесии</b>
					</p>
					<p>
						Провеждам коучинг и терапевтични сесии със служителите,
						които им помагат да:
					</p>

					<p>- Преодолеят стреса и емоционалните блокажи;</p>
					<p>- Развият личния си потенциал;</p>
					<p>- Намерят баланс между професионалния и личния живот.</p>
					<p>
						Насърчавам култура на признателност и взаимна подкрепа
						чрез различни практики.
					</p>

					<p>Екипни дейности</p>
					<p>
						Разработвам програми за подобряване на комуникацията в
						екипите, които включват:
					</p>
					<p>- Упражнения за активното слушане и емпатия;</p>
					<p>
						- Практики за решаване на конфликти по конструктивен
						начин.
					</p>
					<p>
						Въвеждам иновативни подходи за изграждане на доверие и
						ефективно сътрудничество.
					</p>

					<p>
						<b>Създаване на култура на позитивност</b>
					</p>
					<p>
						- Поддържам атмосфера, която насърчава мотивацията,
						принадлежността и удовлетворението на служителите.
					</p>
					<p>
						- Съчетавам психологически и енергийни техники, за да
						създам пространство, където служителите се чувстват
						ценени, вдъхновени и готови да дават най-доброто от себе
						си.
					</p>

					<p>
						<b>Моята квалификация и опит</b>
					</p>
					<p>
						- Имам дългогодишен опит в управлението на корпоративни
						структури, което ми е дало разбиране за нуждите и
						динамиката в големите организации.
					</p>
					<p>
						- Квалифициран съм като енергиен Life Coach, с фокус
						върху трансформиране на личните и професионалните
						блокажи.
					</p>
					<p>
						- Завършила съм психология и активно практикувам като
						психолог, предоставяйки холистична подкрепа на клиентите
						си.
					</p>
					<p>
						- Комбинирам тези умения, за да предложа персонализирани
						решения, които работят както на индивидуално, така и на
						екипно ниво.
					</p>
					<p>
						<b>Ползи за организацията</b>
					</p>
					<p>
						- Повишаване на мотивацията и ангажираността на
						служителите.
					</p>
					<p>- Намаляване на нивата на стрес и бърнаут.</p>
					<p>- Подобряване на междуличностните отношения в екипа.</p>
					<p>
						- Създаване на среда, която задържа ключови таланти и
						привлича нови.
					</p>
					<p>
						- Повишаване на общата продуктивност и креативност в
						компанията.
					</p>
					<p>
						Чрез моята роля като Positive Energy Manager се стремя
						не само да подобрявам работния климат, но и да
						вдъхновявам хората да реализират своя пълен потенциал,
						създавайки хармония между личното и професионалното
						развитие.
					</p>
				</div>
			</div>
			<div className="contact-form-container">
				<h3>Изпратете запитване</h3>
				<ContactForm />
			</div>
		</div>
	);
}
