import reikiImage1 from "../../../assets/images/gallery/reiki_nights/1.jpg";
import reikiImage2 from "../../../assets/images/gallery/reiki_nights/2.jpg";
import reikiImage3 from "../../../assets/images/gallery/reiki_nights/3.jpg";
import reikiImage4 from "../../../assets/images/gallery/reiki_nights/4.jpg";
import reikiImage5 from "../../../assets/images/gallery/reiki_nights/5.jpg";
import reikiImage6 from "../../../assets/images/gallery/reiki_nights/6.jpg";
import taroNightsImage1 from "../../../assets/images/gallery/taro_nights/1.jpg";
import taroNightsImage2 from "../../../assets/images/gallery/taro_nights/2.jpg";
import taroNightsImage3 from "../../../assets/images/gallery/taro_nights/3.jpg";
import taroNightsImage4 from "../../../assets/images/gallery/taro_nights/4.jpg";
import taroNightsImage5 from "../../../assets/images/gallery/taro_nights/5.jpg";
import taroNightsImage6 from "../../../assets/images/gallery/taro_nights/6.jpg";
import taroNightsImage7 from "../../../assets/images/gallery/taro_nights/7.jpg";
import dailiySessionImage1 from "../../../assets/images/gallery/daily_sessions/1.jpg";
import dailiySessionImage2 from "../../../assets/images/gallery/daily_sessions/2.jpg";
import dailiySessionImage3 from "../../../assets/images/gallery/daily_sessions/3.jpg";
import dailiySessionImage4 from "../../../assets/images/gallery/daily_sessions/4.jpg";
import dailiySessionImage5 from "../../../assets/images/gallery/daily_sessions/5.jpg";
import dailiySessionImage6 from "../../../assets/images/gallery/daily_sessions/6.jpg";
import dailiySessionImage7 from "../../../assets/images/gallery/daily_sessions/7.jpg";
import dailiySessionImage8 from "../../../assets/images/gallery/daily_sessions/8.jpg";
import dailiySessionImage9 from "../../../assets/images/gallery/daily_sessions/9.jpg";
import dailiySessionImage10 from "../../../assets/images/gallery/daily_sessions/10.jpg";
import dailiySessionImage11 from "../../../assets/images/gallery/daily_sessions/11.jpg";
import dailiySessionImage12 from "../../../assets/images/gallery/daily_sessions/12.jpg";
import dailiySessionImage13 from "../../../assets/images/gallery/daily_sessions/13.jpg";
import dailiySessionImage14 from "../../../assets/images/gallery/daily_sessions/14.jpg";
import dailiySessionImage15 from "../../../assets/images/gallery/daily_sessions/15.jpg";
import dailiySessionImage16 from "../../../assets/images/gallery/daily_sessions/16.jpg";
import dailiySessionImage17 from "../../../assets/images/gallery/daily_sessions/17.jpg";
import dailiySessionImage18 from "../../../assets/images/gallery/daily_sessions/18.jpg";
import dailiySessionImage19 from "../../../assets/images/gallery/daily_sessions/19.jpg";
import dailiySessionImage20 from "../../../assets/images/gallery/daily_sessions/20.jpg";
import dailiySessionImage21 from "../../../assets/images/gallery/daily_sessions/21.jpg";
import dailiySessionImage22 from "../../../assets/images/gallery/daily_sessions/22.jpg";
import dailiySessionImage23 from "../../../assets/images/gallery/daily_sessions/23.jpg";
import dailiySessionImage24 from "../../../assets/images/gallery/daily_sessions/24.jpg";
import dailiySessionImage25 from "../../../assets/images/gallery/daily_sessions/25.jpg";
import dailiySessionImage26 from "../../../assets/images/gallery/daily_sessions/26.jpg";
import dailiySessionImage27 from "../../../assets/images/gallery/daily_sessions/27.jpg";
import dailiySessionImage28 from "../../../assets/images/gallery/daily_sessions/28.jpg";
import dailiySessionImage29 from "../../../assets/images/gallery/daily_sessions/29.jpg";
import dailiySessionImage30 from "../../../assets/images/gallery/daily_sessions/30.jpg";
import dailiySessionImage31 from "../../../assets/images/gallery/daily_sessions/31.jpg";
import dailiySessionImage32 from "../../../assets/images/gallery/daily_sessions/32.jpg";
import dailiySessionImage33 from "../../../assets/images/gallery/daily_sessions/33.jpg";
import dailiySessionImage34 from "../../../assets/images/gallery/daily_sessions/34.jpg";
import dailiySessionImage35 from "../../../assets/images/gallery/daily_sessions/35.jpg";
import dailiySessionImage36 from "../../../assets/images/gallery/daily_sessions/36.jpg";
import dailiySessionImage37 from "../../../assets/images/gallery/daily_sessions/37.jpg";

const galleryEvents = [
	{
		id: "reiki-nights",
		eventName: "Рейки вечери",
		eventMainImage: reikiImage1,
		eventDescription: "Снимки от проведени рейки вечери",
		eventImages: [
			reikiImage1,
			reikiImage2,
			reikiImage3,
			reikiImage4,
			reikiImage5,
			reikiImage6,
		],
	},
	{
		id: "taro-nights",
		eventName: "Таро вечери",
		eventMainImage: taroNightsImage1,
		eventDescription: "Снимки от проведени таро вечери",
		eventImages: [
			taroNightsImage1,
			taroNightsImage2,
			taroNightsImage3,
			taroNightsImage4,
			taroNightsImage5,
			taroNightsImage6,
			taroNightsImage7,
		],
	},
	{
		id: "daily-sessions",
		eventName: "Дневни семинари",
		eventMainImage: dailiySessionImage1,
		eventDescription: "Снимки от проведени дневни семинари",
		eventImages: [
			dailiySessionImage1,
			dailiySessionImage2,
			dailiySessionImage3,
			dailiySessionImage4,
			dailiySessionImage5,
			dailiySessionImage6,
			dailiySessionImage7,
			dailiySessionImage8,
			dailiySessionImage9,
			dailiySessionImage10,
			dailiySessionImage11,
			dailiySessionImage12,
			dailiySessionImage13,
			dailiySessionImage14,
			dailiySessionImage15,
			dailiySessionImage16,
			dailiySessionImage17,
			dailiySessionImage18,
			dailiySessionImage19,
			dailiySessionImage20,
			dailiySessionImage21,
			dailiySessionImage22,
			dailiySessionImage23,
			dailiySessionImage24,
			dailiySessionImage25,
			dailiySessionImage26,
			dailiySessionImage27,
			dailiySessionImage28,
			dailiySessionImage29,
			dailiySessionImage30,
			dailiySessionImage31,
			dailiySessionImage32,
			dailiySessionImage33,
			dailiySessionImage34,
			dailiySessionImage35,
			dailiySessionImage36,
			dailiySessionImage37,
		],
	},
];

export { galleryEvents };
