import { Helmet } from "react-helmet";
import "../styles/posts.css";

export default function MyPath() {
	return (
		<div className="singlePost">
			<div className="singlePostWrapper">
				{/* <img src={image} alt="break-up" className="singlePostImg" /> */}
				<h1 className="singlePostTitle">
					Моят личен път
					{/* <div className="singlePostEdit">
                        <i className="singlePostIcon fa-regular fa-pen-to-square "></i>
                        <i className="singlePostIcon far fa-trash-alt"></i>
                    </div> */}
				</h1>
				<Helmet>
					<title>Моят личен път</title>
					<meta
						name="description"
						content="Израснах в гр. София, завърших езикова гимназия, после
						бакалавър по икономика в един университет, магистратура
						в друг. Професионалния ми опит винаги е бил насочен към
						мъжкия бизнес свят. След раждането на първото ми дете
						започнах работа в банка, а две години по – късно се
						озовах в Испания, където вече живеех и управлявах
						транспортна фирма, която извършаваше международен
						транспорт, тоест работа 24/7. Бизнеса беше общ с
						тогавашния ми съпруг. Приключението и опитността ми в
						областта и живота в Испания, приключиха с раздялата ми с
						първия ми съпруг. Прибрах се в България, решена да
						започна живота си отначало. Знаех, че брака ми и всичко,
						което ми се случи до този момент са ме научили на много
						и са ми дали уроци, но малко по – късно разбрах, че
						изобщо не съм осъзнавала на какво точно ме беше научил
						живота до онзи момент. Живота ме учеше, аз запомнях, но
						само научавах нещо наизуст, което не стигаше до
						съзнанието ми."
					/>
				</Helmet>
				{/* <div className="singlePostInfo">
                    <span className="singlePostAuthor">
                        Author: <b>Safak</b>
                    </span>
                    <span className="singlePostDate">1 hour ago</span>
                </div> */}
				<div className="singlePostDesc">
					<p>
						Коя съм аз? Моят път. Много често през годините си казвах, че ще
						напиша книга за живота си. Защо ли? Защото е наситен, защото имам
						толкова много какво да разкажа за него. Не е ли това смисъла?
					</p>
					<p>
						Израснах в гр. София, завърших езикова гимназия, после бакалавър по
						икономика в един университет, магистратура в друг. Професионалния ми
						опит винаги е бил насочен към мъжкия бизнес свят. След раждането на
						първото ми дете започнах работа в банка, а две години по – късно се
						озовах в Испания, където вече живеех и управлявах транспортна фирма,
						която извършаваше международен транспорт, тоест работа 24/7. Бизнеса
						беше общ с тогавашния ми съпруг. Приключението и опитността ми в
						областта и живота в Испания, приключиха с раздялата ми с първия ми
						съпруг. Прибрах се в България, решена да започна живота си отначало.
						Знаех, че брака ми и всичко, което ми се случи до този момент са ме
						научили на много и са ми дали уроци, но малко по – късно разбрах, че
						изобщо не съм осъзнавала на какво точно ме беше научил живота до
						онзи момент. Живота ме учеше, аз запомнях, но само научавах нещо
						наизуст, което не стигаше до съзнанието ми.
					</p>
					<p>
						Година и половина след развода ми аз живеех в същия бизнес модел на
						семейство, от който си тръгнах развеждайки се с първия си съпруг.
						Фокус върху материалното, стремеж към материални постижение. Някъде
						тук, обаче, в живота ми се появи моята рейки мастър учителка, която
						освен, че ме инициира в рейки и ми предаде и други два мощни
						енергийни инструмента, ми отвори съзнанието за несъзнаваното. Така
						започна моят път към мен. Защото аз не живеех, не се обичах, не
						почитах женствеността си, не се уважавах, не знаех коя съм. Бях
						това, което обществото, мъжа ми и родителите ми искаха да бъда.
						Родих дъщеря си, през 2018 година. Нейните бебешки години минаха в
						безсънни нощи за мен и дни в работата ми. И въпреки всички мои
						усилия, успехи, отдаденост, аз не се чувствах на мястото си. Живеех
						в несигурност, имах усещането, че семейството ми е основоположено на
						бизнес взаимоотношенията с мъжа ми. И, да, така и се оказа. Срещата
						ми с моята тантра и йога учителка през 2019 година, продължи пътя,
						който бях започнала с рейки. Започнах лична терапия с психотерапевт.
					</p>
					<p>
						Посещавах редица семинари, женски, обучителни, отработвах връзката
						си майката, партньорите. В един момент, когато ясно си давах сметка,
						че съм на път да се разделя и с втория си мъж, поради много сходни
						причини, че ще се наложи да започна живота си отново, с двете си
						деца, че ще трябва да ми подсигуря бъдеще, взех решение, че бизнес
						дамата, бизнес модела, който следвах, трябва да остане в миналото с
						благодарност.
					</p>
					<p>
						Записах се да уча психология, завърших курсове в областта на
						енергийната психология ( травми от минали животи ) , минах
						обучителен курс по работа с вътрешното дете и лекуване на детски
						травми, насочих се към езотерика, кармична астрология. Процеса беше
						болезнен, изпълнен с много страхове, безсънни нощи, физическа,
						емоционална и психическа умора. Днес, обаче, съм една оргазмична и
						обичаща себе си жена. Жена, която вярва в себе си и силата си, но
						през своята женска енергия. Обичам тялото си и често го моля за
						прошка за моментите, когато съм му причинявала дори болка, за да
						изглежда по определен начин. Грижа се за детето в мен. Отделям време
						на децата си.
					</p>
					<p>
						Радвам се на всеки нов ден, благодаря му. Умея да поставям
						здравословни граници в общуването с хората около мен, включително и
						с моите родители. Готова съм да помагам на други жени в пътя си към
						своята женственост, свобода, любов към себе си. Готова съм да предам
						личната си опитност и професионализъм. Бих се радвала да виждам
						повече истински дишащи и живеещи хора, които не са в плен на
						утвърждения, които получаваме още с раждането си, които приемат
						родителите си в сърцата си, които не са роби на страховете си.
					</p>
					<p>Очаквам ви с много трепет и вълнение!</p>
				</div>
			</div>
		</div>
	);
}
