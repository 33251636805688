import "./about.css";
import image from "../../assets/images/stanislava-image.jpg";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

export default function About() {
	return (
		<div className="aboutPost">
			<div className="aboutPostWrapper">
				<img src={image} alt="psychologist" className="aboutPostImg" />
				<h1 className="aboutPostTitle">Станислава Митова</h1>
				<Helmet>
					<title>Информация за мен</title>
					<meta
						name="description"
						content="Казвам се Станислава Митова и съм енергиен лайф коуч. Помагам на
						хора и компании да изчистят блокираната енергия, да създадат нова
						такава и да реализират целите си. Нека активираме вашата творческа
						визия, за да можете да създадете повече от това, което си
						представите!"
					/>
				</Helmet>
				<div className="aboutInfo">
					<span className="aboutProfession">
						Психолог и енергиен коуч
					</span>
				</div>
				<div className="aboutPostDesc">
					<p className="bold-text">Коя съм аз?</p>
					<p>
						Казвам се Станислава Митова и съм енергиен лайф коуч.
						Помагам на хора и компании да изчистят блокираната
						енергия, да създадат нова такава и да реализират целите
						си. Нека активираме вашата творческа визия, за да можете
						да създадете повече от това, което си представите!
					</p>
					<p>
						Това, което ме вдъхнови да уча и да се отдам на
						личностното развитие, лайф коучинг, психология и
						енергийните практики, е моя личен опит. Всичко започна с
						дисфункционалната връзка между моите партньори,
						смесването на личните и бизнес взаимоотношения и тяхното
						разпадане.
					</p>
					<p>
						Прочети повче за това{" "}
						<Link className="click-here" to="/my-path">
							тук
						</Link>
					</p>
					<p>
						Имам магистърска степен по Маркетинг и мениджмънт и над
						15 години професионален опит в областта на мениджмънта и
						управлението на бизнес. Стартирала съм и съм изградила
						не един бизнес. Този опит допълва силно развитото ми
						логическо и ориентирано към резултати мислене и много ми
						помага в моята коучинг практика. Живяла съм, работила
						съм и съм посетила общо повече от 30 държави, което ми
						помага да приемам различията и успешно да работя и с
						хора от други култури и религии.
					</p>
					<p>
						Вярвам, че изключителният коуч се различава от добрия
						коуч по нивото на личностно израстване. Затова посветих
						голяма част от времето и енергията си на работа с личен
						психоаналитик, психотерапевти, коучове от цял свят и
						семинари за личностно развитие. Имам магистърска степен
						„Психологично консултиране“. Сертифициран енергиен
						терапевт съм от д-р Barbara Stone, по метод от
						енергийната психология, наречен Soul Detective Work.
						Имам рейки мастър ниво и завършено обучение по метод за
						лекуване на детски травми. Преминала съм редица обучения
						по метода семейни констелации.
					</p>
					<p>
						Работя предимно индивидуално с персонални коучинг сесии
						с видео връзка или лице в лице. Също така водя и
						семинари, уебинари, групови коучинг сесии и корпоративни
						тренинги.
					</p>
					<p className="bold-text mt-mb-10">
						Какво ще се случи с вас след срещата ви с мен?
					</p>
					<p>
						По време на работата си с мен, вие ще придобиете
						разбиране за сърдечния процес и на свой ред ще изпитате
						истинската интимност, тъй като това е мястото, от което
						творите и ставате цялостни. Ще обсъдим вашите намерения
						за това, което искате да създадете, и ще осветлим всички
						несъответствия между вибрацията на това, което искате, и
						къде е заседнала или съхранена вашата енергия.
					</p>
					<ul>
						<li className="tick-shaped">
							Мога да разкрия защо привличате определени ситуации
							и преживявания, както и кои лични модели се повтарят
							в живота ви и създават блокажи, които ви пречат да
							правите положителни промени.
						</li>
						<li className="tick-shaped">
							Мога да ви помогна да изчистите тези енергийни
							блокове, така че да можете да създадете нови полета
							от възможности за успех във вашата работа, вашите
							взаимоотношения и вашето семейство.
						</li>
						<li className="tick-shaped">
							Ще ви дам инструментите, които са необходими, за да
							освободите негативната енергия и да превърнете
							желанията си в реалност."
						</li>
						<li className="tick-shaped">
							Ще ви въведа във връзката с истинските намерения на
							вашето сърце, създадени от Вселената.
						</li>
						<li className="tick-shaped">
							Ще повишиш увереността в себе си и ще взимаш
							по-добри решения
						</li>
						<li className="tick-shaped">
							Ще се научиш по-добре да организираш времето си и да
							поддържаш висока енергия, за да постигаш своите цели
							и мечти
						</li>
						<li className="tick-shaped">
							Ще можеш да управляваш емоциите си и да общуваш
							по-пълноценно с другите
						</li>
						<li className="tick-shaped">
							Ще се научиш как да управляваш и да се наслаждаваш
							на живота си
						</li>
					</ul>
					<p className="bold-text mt-mb-10">В какво вярвам?</p>
					<p>
						Вярвам, че пътят към промяната на живота ви е като
						промените енергията си. Когато движите енергията през
						сърцето, сърцето трансформира негативните мисли,
						безпокойството, съмнението и страха, в нов начин на
						съществуване. Отваряйки сърцето си, създавате верига от
						движение и поток.
					</p>
				</div>
			</div>
		</div>
	);
}
