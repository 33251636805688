import "../styles/posts.css";
import image from "../../../assets/images/coaching-image-one-on-one.jpg";
import ContactForm from "../../contact-form/contactForm";
import { Helmet } from "react-helmet";

export default function StartupCoaching() {
	return (
		<div className="singlePost">
			<div className="singlePostWrapper">
				<img
					src={image}
					alt="start-up coaching notebook"
					className="singlePostImg"
				/>
				<h1 className="singlePostTitle">Коучинг One on One</h1>
				<Helmet>
					<title>Коучинг One on One</title>
					<meta name="description" content="Насоки, подкрепа, насърчаване и отчетност на всяка фаза от вашето
							пътуване, от планирането до изпълнението. Силно персонализирано
							внимание за тези, които са се ангажирали да постигнат целите си
							СКОРО, а не „някой ден“."/>
				</Helmet>
				<div className="singlePostDesc">
					<div className="highlighted-text">
						{/* <div class="h-divider">
							<div class="shadow"></div>
							<div class="text2">
								<img src="https://t1.gstatic.com/images?q=tbn:ANd9GcQsmMfybMIwoE5etmOIAuvnFWdfv_8C1Bq15urJFqwhhI55FyYNP2YuUA" />
							</div>
						</div> */}

						<div class="h-divider">
							<div class="shadow"></div>
						</div>
						<p>
							Най-високо ниво на постоянна поддръжка, за да ви помогне да
							започнете И завършите силни!
						</p>
						<p>
							Насоки, подкрепа, насърчаване и отчетност на всяка фаза от вашето
							пътуване, от планирането до изпълнението. Силно персонализирано
							внимание за тези, които са се ангажирали да постигнат целите си
							СКОРО, а не „някой ден“.
						</p>
						<div class="h-divider">
							<div class="shadow"></div>
						</div>
					</div>
					<p className="pt-10">
						<b>One on One коучинг е най-добрият вариант, ако вие сте . . .</b>
					</p>
					<ul>
						<li className="tick-shaped">
							Самостоятелен предприемач, в която и да е сфера, готов да се
							гмурне в дълбокото и да постигне по-добри резултати: повече
							въздействие, повече приходи, повече баланс
						</li>
						<li className="tick-shaped">
							Намирате се е началото на вашето пътуване и имате нужда от повече
							индивидуално внимание, докато се впускате в новото си начинание
						</li>
						<li className="tick-shaped">
							Във всеки един етап от бизнеса, в който осъзнаете, че сте се
							опитали да се справите сами, но не сте успели да постигнете целите
							си. (или дори да идентифицирате целите си)
						</li>
						<li className="tick-shaped">
							Имате желание да опитате нови начини за изпълнение на нещата и да
							разберете, че растежът в бизнеса върви ръка за ръка с личния
							растеж
						</li>
						<li className="tick-shaped">
							Ангажирани сте да постигнете целите си по-рано – вместо никога – и
							искате да придобиете уменията и стратегията, за да ги осъществите
						</li>
						<li className="tick-shaped">
							Вдъхновени от предизвикателство и поемане на пълна отговорност за
							действията си; вие търсите ментор, а не спасител
						</li>
						<li className="tick-shaped">
							Готови да пуснете трудностите и да се пуснете по течението на
							вашия бизнес и живот. Усърдна работа? ДА. Страдание? НЕ.
						</li>
					</ul>
					<p className="mt-mb-10">
						<b>Как работи?</b>
					</p>
					<p className="mt-mb-10">
						<b>Коучинг със мен включва:</b>
					</p>
					<p>
						Точно това, от което се нуждаете, за да ви помогне да продължите
						напред ефективно, така че да можете да повлияете на повече хора, да
						печелите повече пари и да живеете живот на удовлетворение и радост:
					</p>
					<ul>
						<li className="tick-shaped">Насоки, подкрепа, структура</li>
						<li className="tick-shaped">Отчетност, блестящи идеи, кураж</li>
						<li className="tick-shaped">
							Прозрение, практически съвети, предложения
						</li>
						<li className="tick-shaped">
							Инструменти, ресурси, работни листове
						</li>
						<li className="tick-shaped">
							Конкретни стъпки за действие, за да продължите напред
						</li>
					</ul>
					<p>
						Редовно планирани коучинг сесии 1:1 плюс поддръжка „точно навреме“
						между сесиите
					</p>
					<ul>
						<li className="tick-shaped">
							Връзки с други клиенти и влиятелни лица, според областта
						</li>
						<li className="tick-shaped">
							Преглед на материали → обратна връзка, ако е необходимо
						</li>
						<li className="tick-shaped">
							И разбира се, много провокативни ситуация, които ще ви помогнат да
							излезете извън зоната си на комфорт, защото там се случва магията!
						</li>
					</ul>
					<p>
						Помагам ви да преминете през вашите блокажи, така че да създадете
						трайна положителна промяна във вашия бизнес и живот. Без повече
						отлагане. Край на хвърлянето на дарц стрелички с надеждата за
						попадение в средата.
					</p>
					<p className="mt-mb-10">
						<b>ПЪЛНА ПРОЗРАЧНОСТ:</b>
					</p>
					<p>
						Коучингът One on One е за хора, които са нетърпеливи да се променят
						и растат и разбират, че няма чудодейно решение – че са необходими
						време, енергия и финансов ангажимент, за да постигнат целите си. Ако
						търсите най-евтиното решение, вместо най-доброто възможно решение,
						тогава това няма да е подходящо. Коучингът One on One е инвестиция
						във ВАС, вашите клиенти и вашия бизнес. Но ако развитието и
						приходите ви са в застой или не искате да губите повече време, а
						искате да получите желаните резултати, тогава това е решението,
						което търсите.
					</p>
					<p>
						Каква е истинската цена, която би платил, ако не продължиш напред и
						не изпълниш мечтите си?
					</p>
				</div>
			</div>
			<div className="contact-form-container">
				<h3>Изпратете запитване</h3>
				<ContactForm />
			</div>
		</div>
	);
}
