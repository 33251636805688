import "./contact.css";

export default function Contact() {
    return (
        <div className="contact">
            <div className="contactWrapper">
                <h1 className="contactTitle">ЗА ВРЪЗКА С МЕН</h1>
                <p className="contactDesc">
                    <i className="contactIcon fa-solid fa-phone">
                        <a href="tel:088 550 6682">088 550 6682</a>
                    </i>
                    <i className="contactIcon fa-solid fa-envelope">
                        <a href="mailto:sani.mitova@gmail.com">
                            sani.mitova@gmail.com
                        </a>
                    </i>
                </p>
            </div>
        </div>
    );
}
