import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { upcomingEvents, pastEvents } from "../eventsData"; // Import event data
import { Helmet } from "react-helmet";
import "../../styles/posts.css";

const SingleEvent = () => {
	const { eventId } = useParams();
	const [event, setEvent] = useState(null);

	useEffect(() => {
		// Find the event in either upcomingEvents or pastEvents array
		const foundEvent =
			upcomingEvents.find((event) => event.id === eventId) ||
			pastEvents.find((event) => event.id === eventId);

		// If the event is found, set it in the state
		if (foundEvent) {
			setEvent(foundEvent);
		}
	}, [eventId]);

	if (!event) {
		return <div>Loading...</div>;
	}

	// Split the event description into an array of lines
	const descriptionLines = event.eventDescription.split("|");
	// Function to detect URLs and wrap them in anchor tags
	function linkify(text) {
		const urlPattern = /(https?:\/\/[^\s]+)/g;
		return text.split(urlPattern).map((part, index) =>
			urlPattern.test(part) ? (
				<a
					key={index}
					href={part}
					target="_blank"
					rel="noopener noreferrer"
				>
					{part}
				</a>
			) : (
				part
			)
		);
	}

	return (
		<div className="singlePost events">
			<div className="singlePostWrapper singleEventWrapper">
				<h1 className="singlePostTitle">{event.eventName}</h1>
				<Helmet>
					<title>{event.eventName}</title>
					<meta name="description" content={event.eventDescription} />
				</Helmet>
				<div className="singleEventCard">
					<img src={event.eventImage} alt={event.eventName} />
					<p className="pt-10">Начало: {event.eventDate}</p>
				</div>
				{/* Render description lines with clickable links */}
				{descriptionLines.map((line, index) => (
					<p className="singlePostDesc pb-5" key={index}>
						{linkify(line)}{" "}
						{/* Apply linkify function to each line */}
					</p>
				))}
			</div>
		</div>
	);
};

export default SingleEvent;
