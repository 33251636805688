import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { programInfo } from "../programsData"; // Import event data
import { Helmet } from "react-helmet";
import "../../styles/posts.css";

const SingleProgram = () => {
	const { programId } = useParams();
	const [program, setProgram] = useState(null);

	useEffect(() => {
		// Find the event in either upcomingEvents or pastEvents array
		const foundEvent =
		programInfo.find((program) => program.id === programId) 

		// If the event is found, set it in the state
		if (foundEvent) {
			setProgram(foundEvent);
		}
	}, [programId]);

	if (!program) {
		return <div>Loading...</div>;
	}

	// Split the event description into an array of lines
	// const descriptionLines = program.programDescription.split("|");
	// Function to detect URLs and wrap them in anchor tags
	function linkify(text) {
		const urlPattern = /(https?:\/\/[^\s]+)/g;
		return text.split(urlPattern).map((part, index) =>
			urlPattern.test(part) ? (
				<a
					key={index}
					href={part}
					target="_blank"
					rel="noopener noreferrer"
				>
					{part}
				</a>
			) : (
				part
			)
		);
	}

	return (
		<div className="singlePost events">
			<div className="singlePostWrapper singleEventWrapper">
				<h1 className="singlePostTitle">{program.programName}</h1>
				<Helmet>
					<title>{program.programName}</title>
					<meta name="description" content={program.programDescription} />
				</Helmet>
				<div className="singleEventCard img-height-auto">
					<img src={program.programImage} alt={program.programName} />
					{/* <p className="pt-10">Начало: {program.programDate}</p> */}
				</div>
				{/* Render description lines with clickable links */}
				<div>{program.programDescription}</div>
				{/* {descriptionLines.map((line, index) => (
					<p className="singlePostDesc pb-5" key={index}>
						{linkify(line)}{" "}
					</p>
				))} */}
			</div>
		</div>
	);
};

export default SingleProgram;
