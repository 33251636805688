import "../styles/posts.css";
import image from "../../../assets/images/energy-coaching-session.jpg";
import ContactForm from "../../contact-form/contactForm";
import { Helmet } from "react-helmet";

export default function EnergyCoaching() {
	return (
		<div className="singlePost">
			<div className="singlePostWrapper">
				<img src={image} alt="taro" className="singlePostImg" />
				<h1 className="singlePostTitle">Енергийна коучинг сесия</h1>
				<Helmet>
					<title>Енергийна коучинг сесия</title>
					<meta
						name="description"
						content="По време на енергийната коучинг сесия, съчетавам инструментите, с
						които работя, а именно рейки, Soul Detective Work, астрология, таро,
						според необходимостта на клиента. Сесията стартира с практика за
						центриране и проверка на енергийните центрове на клиента, т.е.
						чакрите. В хода на разговора и дискутирането на темите които са
						довели клиента при мен, определям терапията, от която той се нуждае."
					/>
				</Helmet>
				<div className="singlePostDesc">
					<p>
						По време на енергийната коучинг сесия, съчетавам инструментите, с
						които работя, а именно рейки, Soul Detective Work, астрология, таро,
						според необходимостта на клиента. Сесията стартира с практика за
						центриране и проверка на енергийните центрове на клиента, т.е.
						чакрите. В хода на разговора и дискутирането на темите които са
						довели клиента при мен, определям терапията, от която той се нуждае.
					</p>
					<p>
						Начина на поведение и ситуациите, в които попадаме в живота си,
						тяхната повторяемост, често се дължат на преживени травми в нашия
						настоящ, минал живот или живота на нашите предци. Задачата на метода
						от енергийната психология SDW е именно, тази, да излекуваме
						травмата, която дефинираме по време на коучинг сесията ни. За да
						постигнем това и да влезем в този процес, обаче е добре преди това
						да сме преминали през рейки терапия и енергийно да подготвим тялото
						си за следващия процес. Астрологията, която аз практикувам се
						занимава с пътят на нашата душа. Следователно я ползваме, когато
						определяме какво е онова кармично действие, което се е случвало в
						предишен живот, което повтаряме сега и което следва да променим, за
						да сме способни да живеем пълноценно в засегната област от нашия
						живот.
					</p>
					<p>
						Таро рийдинга включвам по усет, в момент, който се нуждая и от
						езотеричен прочит. Така енергийната коучинг сесия е едно пътуване
						във време и пространство, освобождаване и събиране на фрагментите на
						нашата душа, лекуване на травмите и зареждане с универсална енергия.
						Телата ни, емоциите ни и душата ни празнуват и са на мястото си,
						пристигнали са у дома.
					</p>
				</div>
			</div>
			<div className="contact-form-container">
				<h3>Изпратете запитване</h3>
				<ContactForm />
			</div>
		</div>
	);
}
