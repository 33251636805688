import "../styles/posts.css";
import image from "../../../assets/images/inner-child-healing.jpg";
import ContactForm from "../../contact-form/contactForm";
import { Helmet } from "react-helmet";

export default function InnerChildHealing() {
	return (
		<div className="singlePost">
			<div className="singlePostWrapper">
				<img src={image} alt="taro" className="singlePostImg img-width-auto" />
				<h1 className="singlePostTitle">
					Лечение на вътрешното ни дете
					{/* <div className="singlePostEdit">
                        <i className="singlePostIcon fa-regular fa-pen-to-square "></i>
                        <i className="singlePostIcon far fa-trash-alt"></i>
                    </div> */}
				</h1>
				<Helmet>
					<title>Лечение на вътрешното ни дете</title>
					<meta
						name="description"
						content="Вътрешното дете е част от нашата психика, не е нещо
						отделно, но в този процес го разглеждаме все едно е
						отделно от нас, за да можем да видим моделите от
						дистанцирана гледна точка, да ги преживеем отново, само,
						че от позицията на тук и сега ( ресурсно- ориентиран
						подхос )."
					/>
				</Helmet>
				{/* <div className="singlePostInfo">
                    <span className="singlePostAuthor">
                        Author: <b>Safak</b>
                    </span>
                    <span className="singlePostDate">1 hour ago</span>
                </div> */}
				<div className="quote">
					<p>
						"Върнете се обратно. Дори само светлината на вашето осъзнаване
						лекува. Тя е сила за нещо ново и добро. Всичко, което вашето
						съзнание осветлява, ще бъде излекувано. Човек, който поглежда към
						миналото си, се освобождава от него. Тогава миналото не може да го
						мъчи, няма повече власт на него и приключва. Миналото не бива да се
						намесва в нашето съществуване. Когато то е излекувано, ние сме на
						разположение за настоящето, за целия ни живот."
					</p>
					<p className="quoteAuthor">Ошо</p>
				</div>
				<div className="singlePostDesc">
					<p>
						Лечение на вътрешното ни дете Първичната терапия се развива през
						60-те години на 20-ти век от Артър Джанов. Той разработва техники,
						помагащи на хората, преминаващи през процеса, да изхвърлят потиснати
						емоции, да ги трансформират и да достигнат до нови прозрения и
						емоционално развитие към разкриване на истинската си ( вродена )
						същност.
					</p>
					<p>
						Как да разберем травмите на вътрешното си дете и да се върнем към
						здравословни отношения като партньори, родители, терапевти?
					</p>
					<p>
						Вътрешното дете е част от нашата психика, не е нещо отделно, но в
						този процес го разглеждаме все едно е отделно от нас, за да можем да
						видим моделите от дистанцирана гледна точка, да ги преживеем отново,
						само, че от позицията на тук и сега ( ресурсно- ориентиран подхос ).
					</p>
					<p>
						Сега, като възрастни, ние можем да действаме по по – различен начин,
						защото не сме „зависими“ от средата си, родителите си и
						обстоятелствата така, както сме били в детството. Това ни дава
						възможност да преосмислим миналото си, да освободим някои емоции и
						това ни дава възможност в настоящия момент да имаме избор. Изборът
						да реагираме по по- различен начин, отколкото тогава, когато сме
						били в една зависима ситуация. Предпоставката тук е, че хората в
						нашия живот, ситуациите в нашия живот сега, всъщност до голяма
						степенса повторение на минали ситуации, тоест привличаме си хора,
						независимо дали са партньори , биснес партньори, приятели, които ни
						дават отражение на някакви вътрешни динамики, които носим от
						детството. Връщайки се назад, ние виждаме какво всъщност носим,
						каква е структурата на нашето его, как сме се изградили като хора и
						кое в нашата личност е нездраво за нас самите, кое ни пречи да
						живеем пълноценно или да имаме правилните отношения и т.н.
					</p>
					<p>
						Това е процес на осъзнаване, на трасформиране на стари емоции и ни
						помага да се върнем към усещането, че напускаме миналото, което
						обикновено е неосъзнато, което автоматично ни завърта в някакви
						ситуации. Преминаването през този процес ни дава възможност да
						действаме „тук и сега“, а не от миналите травми и преживявания.
						Всеки носи в себе си образът на себе си като дете. Този образ за
						всеки човек излиза в една определена възраст и обикновено това е
						възрастта, в която ние сме преживели някаква травма или значително
						събитие и , така да се каже, на това място сме спрели да растем.
						Едновременно с това, обаче, вътрешното дете е мястото на най-
						големия ни потенциал, защото символично В.Д. е всичко, което съдържа
						нашите таланти, което е заложено от Господ и вселената по рождение,
						нашата същност. Тоест В.Д. е едновременно положителен образ в нашия
						живот, но той носи и паметта за травматичните ситуации, за нещата,
						които са ни наранили или от където сме спрели да се развиваме
						емоционално.
					</p>
					<p>
						Лечителното в този процес е освен, че виждаме какво се е случило в
						миналото, установяваме една нова връзка между нас като възрастен и
						детето вътре в нас. Когато сме деца, ние сме в една подчинена
						ситуация, защото зависим от нашите родители, не можем да се грижим
						за себе си и т.н. поради тази причина, ние гледаме да се адаптираме
						към средата. И от там се получават всички компромиси, нещата, които
						е трябвало да потиснем в себе си, като например, не можем да сме
						гневни на родителите си. Поради тази причина остава един застинал
						гняв. Той се намесва в отношенията ни в ежедневието ни сега. Така да
						се каже, „изкарваме си го“ на някой друг.
					</p>
				</div>
			</div>
			<div className="contact-form-container">
				<h3>Изпратете запитване</h3>
				<ContactForm />
			</div>
		</div>
	);
}
