import "../styles/posts.css";
import image from "../../../assets/images/stanislava-personal-coaching-therapy-image.jpg";
import ContactForm from "../../contact-form/contactForm";
import { Helmet } from "react-helmet";

export default function PersonalCoachingTherapy() {
	return (
		<div className="singlePost">
			<div className="singlePostWrapper">
				<img src={image} alt="taro" className="singlePostImg" />
				<h1 className="singlePostTitle">Индивидуална коучинг терапия</h1>
				<Helmet>
					<title>Индивидуална коучинг терапия</title>
					<meta name="description" content="По време на индивидуална лайф коуч сесия с мен ще подобрите
						определени аспекти на своя живот и постигнете конкретни цели. Лайф
						коучингът се фокусира върху настоящето и бъдещето, с цел да помогне
						на клиента да идентифицира и постигне лични или професионални цели.
						Не ви предоставям готови решения, а по-скоро насърчавам клиента да
						открие собствените си отговори и стратегии."/>
				</Helmet>
				<div className="singlePostDesc">
					<p>
						По време на индивидуална лайф коуч сесия с мен ще подобрите
						определени аспекти на своя живот и постигнете конкретни цели. Лайф
						коучингът се фокусира върху настоящето и бъдещето, с цел да помогне
						на клиента да идентифицира и постигне лични или професионални цели.
						Не ви предоставям готови решения, а по-скоро насърчавам клиента да
						открие собствените си отговори и стратегии.
					</p>
					<p>
						Лайф коучинг може да се фокусира върху различни области на живота,
						включително професионални цели, лични взаимоотношения, баланс между
						работа и личен живот, самопризнание и други. Важно е да се отбележи,
						че лайф коучинг не предоставя конкретни решения, а по-скоро
						насърчава клиента да осъзнае своите възможности и да вземе
						отговорност за своите решения.
					</p>
					<p>
						По време на коучинг сесиите с мен очертаваме няколко ключови етапа,
						които помагат на клиента да развие ясно разбиране за своите цели, да
						преодолее препятствия и да направи напредък.
					</p>
					<p>Ето обобщение на общите етапи:</p>
					<div className="grid-view-container">
						<div className="grid-view-element">
							<h3 className="grid-view-element-heading">
								Среща и Установяване на Стартова Точка
							</h3>
							<ul>
								<li className="tick-shaped">
									Първата сесия обикновено е посветена на запознаване и
									установяване на връзка между мен и клиента
								</li>
								<li className="tick-shaped">
									Целта е да се разбере текущото състояние на клиента и какви са
									неговите/нейните цели
								</li>
							</ul>
						</div>
						<div className="grid-view-element">
							<h3 className="grid-view-element-heading">
								Определение на Цели и Приоритети
							</h3>
							<ul>
								<li className="tick-shaped">
									С клиента работим заедно, за да определим конкретни, измерими,
									постижими, релевантни и времеви (SMART) цели
								</li>
								<li className="tick-shaped">
									Осигурява се ясно определение на това, какъв вид промяна или
									постижение се търси
								</li>
							</ul>
						</div>
						<div className="grid-view-element">
							<h3 className="grid-view-element-heading">
								Анализ на Текущото Състояние
							</h3>
							<ul>
								<li className="tick-shaped">
									Коучингът включва преглед на текущите силни страни и
									предизвикателства, с които се сблъсква клиента
								</li>
								<li className="tick-shaped">
									Целта е да се определят областите, в които е необходимо
									допълнително внимание и работа
								</li>
							</ul>
						</div>
						<div className="grid-view-element">
							<h3 className="grid-view-element-heading">
								Създаване на План за Действие
							</h3>
							<ul>
								<li className="tick-shaped">
									Създаваме план за действие, който включва конкретни стъпки,
									които клиентът трябва да предприеме, за да постигне своите
									цели
								</li>
								<li className="tick-shaped">
									Планът за действие може да включва краткосрочни и дългосрочни
									цели, както и мерки за оценка на напредъка
								</li>
							</ul>
						</div>
						<div className="grid-view-element">
							<h3 className="grid-view-element-heading">
								Изпълнение и Поддържане
							</h3>
							<ul>
								<li className="tick-shaped">
									Клиентът изпълнява плана за действие, като се фокусира върху
									предприемането на конкретни стъпки и решения
								</li>
								<li className="tick-shaped">
									Предоставям подкрепа, мотивация и инструменти за преодоляване
									на препятствия
								</li>
							</ul>
						</div>
						<div className="grid-view-element">
							<h3 className="grid-view-element-heading">Оценка и Преглед</h3>
							<ul>
								<li className="tick-shaped">
									На определени интервали се прави оценка на напредъка към
									постигане на целите
								</li>
								<li className="tick-shaped">
									Планът се преглежда и коригира, ако е необходимо, за да се
									осигури, че клиентът продължава напред успешно
								</li>
							</ul>
						</div>
						<div className="grid-view-element">
							<h3 className="grid-view-element-heading">
								Завършване и Оценка на Промяната
							</h3>
							<ul>
								<li className="tick-shaped">
									Когато клиентът постигне своите цели или постигне съществено
									напредък, сесиите могат да приключат
								</li>
								<li className="tick-shaped">
									По време на последната сесия се прави оценка на промяната и се
									обсъждат стратегии за поддържане на постигнатия напредък в
									бъдеще
								</li>
							</ul>
						</div>
					</div>
					<p>
						Тези етапи могат да варират в зависимост от индивидуалните нужди и
						цели на клиента.
					</p>
					<p>
						Работата с мен може да има редица положителни резултати и
						въздействия върху различни аспекти на живота. Тук са някои от
						потенциалните ползи, които можете да постигнете:
					</p>
					<ul>
						<li className="tick-shaped bold-text">
							Яснота относно Целите и Приоритетите
						</li>
						<li className="tick-shaped bold-text">
							По-Добро Разбиране на Себе Си
						</li>
						<li className="tick-shaped bold-text">
							Преодоляване на Препятствия и Самоограничения
						</li>
						<li className="tick-shaped bold-text">
							Развитие на Лични и Професионални Умения
						</li>
						<li className="tick-shaped bold-text">
							По-Голяма Мотивация и Самодисциплина
						</li>
						<li className="tick-shaped bold-text">
							По-Добри Лични Взаимоотношения
						</li>
						<li className="tick-shaped bold-text">
							По-Добър Баланс Между Работа и Личен Живот
						</li>
					</ul>
					<p>
						Важно е да се отбележи, че конкретните резултати могат да варират в
						зависимост от индивида и от това колко активно участвате в процеса
						на лайф коучинг. Целта е да създадете положителни промени в живота
						си, които ще продължат да се отразяват и след завършване на коучинг
						програмата.
					</p>
				</div>
			</div>
			<div className="contact-form-container">
				<h3>Изпратете запитване</h3>
				<ContactForm />
			</div>
		</div>
	);
}
