import "../styles/posts.css";
import image from "../../../assets/images/break-up-image.jpg";
import { Helmet } from "react-helmet";

export default function BreakUp() {
	return (
		<div className="singlePost">
			<div className="singlePostWrapper">
				<img src={image} alt="break-up" className="singlePostImg" />
				<h1 className="singlePostTitle">
					Раздялата за мен, за жената в мен
					{/* <div className="singlePostEdit">
                        <i className="singlePostIcon fa-regular fa-pen-to-square "></i>
                        <i className="singlePostIcon far fa-trash-alt"></i>
                    </div> */}
				</h1>
				<Helmet>
					<title>Раздялата за мен</title>
					<meta
						name="description"
						content="Изпитвам необходимостта да ви разкажа за моята последна
						година. Преди да започна да разказвам, искам да
						отбележа, че тя е продължение на темата с раздялата, но
						от гледна точка на жената, както и да ви кажа, че
						колкото и катарзисна да беше тя, не си я представям по –
						добра, по- истинска, по- градивна, по-променяща. Никога
						не съм била по-съзнателна в живота си и по – дишаща в
						него."
					/>
				</Helmet>
				<div className="singlePostDesc">
					<p>
						Изпитвам необходимостта да ви разкажа за моята последна
						година. Преди да започна да разказвам, искам да
						отбележа, че тя е продължение на темата с раздялата, но
						от гледна точка на жената, както и да ви кажа, че
						колкото и катарзисна да беше тя, не си я представям по –
						добра, по- истинска, по- градивна, по-променяща. Никога
						не съм била по-съзнателна в живота си и по – дишаща в
						него.
					</p>
					<p>
						Последните няколко години от личния ми живот ме
						подтикнаха да се занимавам дори професионално с
						психология, коучинг и енергийни практики, за да мога да
						се справя с момента, който знаех, че ще настъпи рано или
						късно, а той беше да се разделя и с втория си мъж, от
						който имам дете. Повярвайте ми, направих всичко
						възможно, към онзи момент, за да спася взаимоотношенията
						ни.
					</p>
					<p>
						Събудих се сутринта на 28.07.2022 година, направих си
						кафе и знаех, че от този ден нататък нищо няма да е
						същото. Знаех, че няма да е лесно, но вярвах, че ще се
						справя. Защо нямаше да е лесно? Защото това бяха 7
						години, през които аз бях напуснала успешната си работа,
						бях се отдала на изграждането на „мечтан“ живот.
					</p>
					<p>
						Работех във фирмата на мъжа ми и я развивахме успешно
						заедно, със същия този успех успявах да менажирам и
						дома, децата, ваканциите ни и всичко до смяната на
						гумите на автомобили ни. Да, точно така. Жената, която
						може всичко, за която няма „ не може“. Разбира се, че
						успявах във всичко това с помощта на моята женска
						енергия, но тя беше насочена изцяло в материалния свят.
					</p>
					<p>
						И така, онази сутрин, знаех, че този живот си тръгна,
						заедно с бившия ми мъж и багажа му. В рамките на няколко
						месеца се наложи да се откажа от всичко, което бяхме
						придобили и изградили заедно, да продам дома си и дори
						да върна средства, изнесох децата си под наем, докато
						стане готово новото ни жилище, станах наемен работник в
						собствената си фирма ( естествено за кратко, след това
						си тръгнах ).
					</p>
					<p>
						Реших да събера смелост и да започна нов професионален
						път, като приложа всички натрупани знания през
						последните години, от обучения, курсове, семинари и да
						помагам на всички, които имат нужда да се справят със
						своите предизвикателства в живота. Днес казвам
						благодаря! Благодаря, че го срещнах, благодаря за всичко
						преживяно, за всичко научено и за това, че си тръгна.
					</p>
					<p>
						Благодаря на себе си, че събрах смелост да пусна всичко,
						което пуснах през тази година. Благодаря на терапевтите
						ми и на приятелите ми, без тях щеше да е още по –
						трудно. Днес, вече съм простила, простих на себе си и на
						него.
					</p>
				</div>
			</div>
		</div>
	);
}
