import "../styles/posts.css";
// import image from "../../../assets/images/reiki.jpg";
import ContactForm from "../../contact-form/contactForm";
import { Helmet } from "react-helmet";

export default function Prices() {
	return (
		<div className="singlePost prices-post">
			<div className="singlePostWrapper pricelist-wrapper">
				{/* <img src={image} alt="reiki" className="singlePostImg img-width-auto" /> */}
				<h1 className="singlePostTitle">
					Цени на услуги и терапии
					{/* <div className="singlePostEdit">
                        <i className="singlePostIcon fa-regular fa-pen-to-square "></i>
                        <i className="singlePostIcon far fa-trash-alt"></i>
                    </div> */}
				</h1>
				<Helmet>
					<title>Цени на услуги и терапии</title>
					<meta
						name="description"
						content="Представям Ви цените за услугите, които извършвам."
					/>
				</Helmet>
				{/* <div className="singlePostInfo">
                    <span className="singlePostAuthor">
                        Author: <b>Safak</b>
                    </span>
                    <span className="singlePostDate">1 hour ago</span>
                </div> */}
				<div className="singlePostDesc pricelist">
					<ul>
						<li className="tick-shaped">
							Индивидуална лайф коуч сесия - 80 лв/50 мин.
						</li>
						{/* <li className="tick-shaped">
							Отговор на въпрос онлайн с помощта на таро карти -
							20 лв/20 мин.
						</li>
						<li className="tick-shaped">
							Седмичен таро рийдинг - 30 лв/30 мин.
						</li>
						<li className="tick-shaped">
							Индивидуална онлайн коучинг сесия с разглеждане на
							въпрос с помощта на таро - 80 лв/50 мин.
						</li> */}
						<li className="tick-shaped">
							Рейки тарпия - 70 лв/50 мин.
						</li>
						<li className="tick-shaped">
							Сесия за лекуване на детски травми - 80 лв/50 мин.
						</li>
						<li className="tick-shaped">
							Сесия за лекуване на травми от минали животи - 80
							лв/50 мин.
						</li>
					</ul>
					{/* <p>Индивидуална онлайн коучинг сесия с разглеждане на въпрос с помощта на таро - 70 лв/50 мин.</p>
					<p>Отговор на въпрос онлайн - 20 лв/20 мин.</p>
					<p>Седмичен рийдинг онлайн - 30 лв/30 мин.</p>
					<p>Индивидуална лайф коуч сесия - 80 лева/50 мин.</p>
					<p>Рейки тарпия - 70 лева/50 мин.</p>
					<p>Сесия за лекуване на детски травми - 80 лева/50 мин.</p>
					<p>Сесия за лекуване на травми от минали животи - 80 лева/50 мин.</p> */}
				</div>
			</div>
			<div className="contact-form-container">
				<h3>Изпратете запитване</h3>
				<ContactForm />
			</div>
		</div>
	);
}
