import "./footer.css";

export default function Footer() {
	return (
		<footer className="footer">
			<div className="footerContent">
				<p>Станислава Митова - Психолог и енергиен коуч | Всички права запазени!</p>
				{/* <p>&copy; {new Date().getFullYear()} Your Website Name</p> */}
				{/* <p>All rights reserved</p> */}
			</div>
		</footer>
	);
}
