import programImage1 from "../../../assets/images/programs/programImage1.jpg";
import programImage2 from "../../../assets/images/programs/programImage2.jpg";
import programImage3 from "../../../assets/images/programs/programImage3.jpg";

const programInfo = [
	{
		id: "personal-boundaries",
		programName: `Онлайн програма: "Постави граници и заживей във вътрешен мир"`,
		programShortName: "Постави граници и заживей във вътрешен мир",
		programShortDesc:
			"Да се научим да поставяме лични граници в общуването с хората и света около нас е ключов елемент за постигане на вътрешен мир и емоционално здраве. ",
		programDescription: (
			<>
				<p className="singlePostDesc pb-5">
					<b>Описание на програмата:</b> Да се научим да поставяме
					лични граници в общуването с хората и света около нас е
					ключов елемент за постигане на вътрешен мир и емоционално
					здраве. Тази едномесечна програма ще ви помогне да разберете
					същността на границите, да идентифицирате причините за
					техния недостиг и да изградите нови умения, които ще
					подобрят вашите взаимоотношения и качеството на живота ви.
				</p>
				<p className="singlePostDesc pb-5">
					<b>Какво ще получите в програмата?</b>
					<ul>
						<li>
							1. <b>14 лекции</b>, разделени в две части.
						</li>
						<li>
							2. <b>Дискусионни лайф сесии</b> веднъж седмично с
							практически упражнения.
						</li>
						<li>
							3. <b>Чат канал за общуване</b> и подкрепа в
							Телеграм.
						</li>
					</ul>
				</p>
				<br />
				<p className="singlePostDesc pb-5">
					<b>Модул 1: Въведение в личните граници</b>
				</p>
				<p className="singlePostDesc pb-5">
					<b>Тема:</b> Какво са личните граници и защо са важни?
					<b>Съдържание:</b>
					<ul>
						<li>
							1. <b>Определение за лични граници:</b>
							<ul>
								<li>
									- Какво представляват границите и как влияят
									на емоционалното и психическото здраве.
								</li>
								<li>
									- Очаквания и потребности в отношенията.
								</li>
							</ul>
						</li>
						<li>
							2. <b>Смисълът на границите:</b>
							<ul>
								<li>- Защо те са форма на грижа за себе си.</li>
								<li>
									- Как границите предпазват от изтощение и
									прекомерно себераздаване.
								</li>
							</ul>
						</li>
						<li>
							3. <b>Как изглеждат нарушените граници:</b>
							<ul>
								<li>
									- Признаци и последици от замъглените или
									липсващи граници.
								</li>
								<li>
									- Връзката между травмите и неспособността
									да казваме "НЕ".
								</li>
							</ul>
						</li>
					</ul>
				</p>
				<p className="singlePostDesc pb-5">
					<b>Практически задачи:</b>
					<ul>
						<li>
							- Самоанализ: "Как изглеждат моите граници в
							момента?"
						</li>
						<li>
							- Упражнение: "Първи стъпки в идентифициране на
							здравословни граници."
						</li>
					</ul>
				</p>
				<p className="singlePostDesc pb-5">
					<b>Модул 2: Причини за липса на граници</b>
				</p>
				<p className="singlePostDesc pb-5">
					<b>Тема:</b> Защо се затрудняваме да поставяме граници?
				</p>
				<p className="singlePostDesc pb-5">
					<b>Съдържание:</b>
					<ul>
						<li>
							1. <b>Корени на проблема:</b>
							<ul>
								<li>
									- Влияние на семейната среда и възпитанието.
								</li>
								<li>
									- Травми от детството и как те формират
									трудности при поставянето на граници.
								</li>
							</ul>
						</li>
						<li>
							2. <b>Често срещани пречки:</b>
							<ul>
								<li>- Страх от отхвърляне и конфликти.</li>
								<li>
									- Желание да се харесаме на другите (синдром
									на угодничество).
								</li>
								<li>
									- Нереалистични очаквания към себе си и
									ролята на "супергерой".
								</li>
							</ul>
						</li>
						<li>
							3. <b>Шестте вида граници:</b>
							<ul>
								<li>
									- Физически, емоционални, времеви,
									сексуални, интелектуални и материални
									граници.
								</li>
							</ul>
						</li>
					</ul>
					<p className="singlePostDesc pb-5">
						<b>Практически задачи:</b>
					</p>
					<ul>
						<li>
							- Упражнение: "Идентифициране на зоните, в които
							най-често правя компромиси."
						</li>
						<li>
							- Практика за казване на "НЕ": Примерни сценарии и
							тяхното отиграване.
						</li>
					</ul>
				</p>
				<p className="singlePostDesc pb-5">
					<b>Модул 3: Изграждане на здравословни граници</b>
				</p>
				<p className="singlePostDesc pb-5">
					<b>Тема:</b> Как да разпознаваме, заявяваме и защитаваме
					границите си?
				</p>
				<p className="singlePostDesc pb-5">
					<b>Съдържание:</b>
				</p>
				<p className="singlePostDesc pb-5">
					1. <b>Как да поставяме ясни граници:</b>
					<ul>
						<li>- Разпознаване на собствените нужди.</li>
						<li>
							- Изграждане на увереност да заявяваме желанията си.
						</li>
					</ul>
				</p>
				<p className="singlePostDesc pb-5">
					2.<b>Граници в различни сфери на живота:</b>
					<ul>
						<li>- Семейство.</li>
						<li>- Романтични отношения.</li>
						<li>- Приятелства.</li>
						<li>- Работно място.</li>
						<li>- Социални медии и технологии.</li>
					</ul>
				</p>
				<p className="singlePostDesc pb-5">
					3.<b>Как да запазим границите си пред другите:</b>
					<ul>
						<li>- Уверено изразяване на нуждите си.</li>
						<li>- Техники за управление на конфликтни ситуации.</li>
					</ul>
				</p>
				<p className="singlePostDesc pb-5">
					<b>Практически задачи:</b>
					<ul>
						<li>
							- Упражнение: "Направете списък с нови граници за
							всяка сфера на живота."
						</li>
						<li>
							- Медитация: "Визуализиране на здравословно
							пространство около себе си."
						</li>
					</ul>
				</p>

				<p className="singlePostDesc pb-5">
					<b>Модул 4: Поддържане на вътрешния мир</b>
				</p>
				<p className="singlePostDesc pb-5">
					<b>Тема:</b> Как да живеем в баланс, без да жертваме себе
					си?
				</p>
				<p className="singlePostDesc pb-5">
					<b>Съдържание:</b>
					1. <b>Границите като част от живота:</b>
					<ul>
						<li>- Превръщане на поставянето на граници в навик.</li>
						<li>
							- Постоянно оценяване и адаптиране на границите.
						</li>
					</ul>
				</p>
				<p className="singlePostDesc pb-5">
					2.
					<b>Какво да правим, когато границите ни бъдат нарушени:</b>
					<ul>
						<li>
							- Разпознаване на манипулация и токсично поведение.
						</li>
						<li>
							- Техники за възстановяване на нарушените граници.
						</li>
					</ul>
				</p>
				<p className="singlePostDesc pb-5">
					3.<b>Свободата да бъдем себе си:</b>
					<ul>
						<li>
							- Как границите ви помагат да живеете автентично.
						</li>
						<li>
							- Създаване на визия за живот с уважение към
							собствените нужди.
						</li>
					</ul>
				</p>
				<p className="singlePostDesc pb-5">
					<b>Практически задачи:</b>
					<ul>
						<li>
							- Ритуал за освобождаване от емоционалния товар:
							"Прощавам и се освобождавам."
						</li>
						<li>
							- Създаване на личен манифест за баланс и вътрешен
							мир.
						</li>
					</ul>
				</p>
				<p className="singlePostDesc pb-5">
					<b>Как протича програмата?</b>
					<ul>
						<li>
							1. <b>14 лекции:</b>
							<br />- Видеа и текстови материали, които можете да
							гледате и четете в удобно за вас време.
						</li>
						<li>
							2. <b>Дискусионни лайф сесии:</b>
							<br />- Всяка седмица (по 1 час) ще имате възможност
							да задавате въпроси и да работите върху практически
							задачи с моя подкрепа.
						</li>
						<li>
							3. <b>Чат канал за дискусии:</b>
							<br />- Постоянна връзка с другите участници, където
							ще можете да споделяте опит и да получавате
							подкрепа.
						</li>
					</ul>
				</p>
				<p className="singlePostDesc pb-5">
					<b>Какво ще постигнете?</b>
					<ul>
						<li>
							- Ще разберете какво са личните граници и защо са
							важни.
						</li>
						<li>
							- Ще се научите да разпознавате и заявявате своите
							нужди.
						</li>
						<li>
							- Ще изградите нови, здравословни навици за
							комуникация.
						</li>
						<li>
							- Ще постигнете вътрешен мир и увереност, без да
							жертвате себе си.
						</li>
					</ul>
				</p>
				<p className="singlePostDesc pb-5">
					Програмата стартира на 1-во число всеки месец. Присъединете
					се и направете първата стъпка към по-добър и балансиран
					живот!
				</p>
			</>
		),
		programImage: programImage1,
	},
	{
		id: "toxic-relationships",
		programName: `Онлайн програма: "Освободи се от токсичните връзки и изгради себе си"`,
		programShortName: "Освободи се от токсичните връзки и изгради себе си",
		programShortDesc: "",
		programDescription: (
			<>
				<p className="singlePostDesc pb-5">
					<b>Модул 1: Разбиране на проблема</b>
				</p>
				<p className="singlePostDesc pb-5">
					<b>Тема:</b> Защо привличаме нарцистични партньори?
				</p>
				<p className="singlePostDesc pb-5">
					<b>Цели:</b>
					<ul>
						<li>
							- Разбиране на нарцистичните черти и динамиката във
							връзката.
						</li>
						<li>
							- Идентифициране на личните травми, които ни
							свързват с тези партньори.
						</li>
						<li>
							- Разбиране на ролята на детството, семейните модели
							и съзависимостта.
						</li>
					</ul>
				</p>

				<p className="singlePostDesc pb-5">
					<b>Съдържание:</b>
					1. <b>Какво е нарцисизъм?</b>
					<ul>
						<li>
							- Основни характеристики на нарцистичния партньор.
						</li>
						<li>
							- Фази на връзката: идеализация, девалвация и
							отхвърляне.
						</li>
					</ul>
				</p>
				<p className="singlePostDesc pb-5">
					2.
					<b>Какво ни привлича към нарцистичните партньори?</b>
					<ul>
						<li>
							- Влиянието на семейната среда и детските травми.
						</li>
						<li>
							- Ролята на ниската самооценка и нуждата от
							одобрение.
						</li>
					</ul>
				</p>
				<p className="singlePostDesc pb-5">
					3.<b>Самоанализ: Каква е моята роля?</b>
					<ul>
						<li>
							- Тест за идентифициране на вашите модели на
							поведение във връзките.
						</li>
					</ul>
				</p>
				<p className="singlePostDesc pb-5">
					<b>Практически упражнения:</b>
					<ul>
						<li>
							- Журналинг: "Какви модели от детството си откривам
							във връзките си?"
						</li>
						<li>
							- Създаване на списък с "червени знамена" за
							нарцистично поведение.
						</li>
					</ul>
				</p>

				<p className="singlePostDesc pb-5">
					<b>Модул 2: Лични граници и емоционална свобода</b>
				</p>
				<p className="singlePostDesc pb-5">
					<b>Тема:</b> Как да излезем от токсичния цикъл?
				</p>
				<p className="singlePostDesc pb-5">
					<b>Цели:</b>
					<ul>
						<li>
							- Осъзнаване на манипулативните тактики на
							нарцистичния партньор.
						</li>
						<li>- Укрепване на личните граници.</li>
						<li>- Развиване на самоувереност и независимост.</li>
					</ul>
				</p>

				<p className="singlePostDesc pb-5">
					<b>Съдържание:</b>
					1. <b>Манипулативните тактики на нарцистичния партньор:</b>
					<ul>
						<li>- Газлайтинг: Какво е и как да го разпознаем?</li>
						<li>- Емоционален шантаж и цикълът на вина.</li>
					</ul>
				</p>
				<p className="singlePostDesc pb-5">
					2.
					<b>Как да поставяме граници:</b>
					<ul>
						<li>- Какво представляват здравите граници?</li>
						<li>
							- Практични техники за "НЕ" и отказване от токсични
							влияния.
						</li>
					</ul>
				</p>
				<p className="singlePostDesc pb-5">
					3.<b>Как да се справим със страха от изоставяне:</b>
					<ul>
						<li>- Разбиране на страха.</li>
						<li>- Техники за работа с вътрешната несигурност.</li>
					</ul>
				</p>
				<p className="singlePostDesc pb-5">
					<b>Практически упражнения:</b>
					<ul>
						<li>
							- Практика: Написване на "Граници, които ще спазвам
							в бъдещи отношения".
						</li>
						<li>
							- Медитация: Визуализация за укрепване на личните
							граници.
						</li>
					</ul>
				</p>

				<p className="singlePostDesc pb-5">
					<b>Модул 3: Лечение на травмите</b>
				</p>
				<p className="singlePostDesc pb-5">
					<b>Тема:</b> Лечение на емоционалните рани и възстановяване
					на личността.
				</p>
				<p className="singlePostDesc pb-5">
					<b>Цели:</b>
					<ul>
						<li>
							- Работа с вътрешното дете и травмите от миналото.
						</li>
						<li>
							- Преодоляване на зависимостта от одобрението на
							другите.
						</li>
						<li>
							- Изграждане на здравословна връзка със себе си.
						</li>
					</ul>
				</p>

				<p className="singlePostDesc pb-5">
					<b>Съдържание:</b>
					1. <b>Връзка с вътрешното дете:</b>
					<ul>
						<li>
							- Как травмите от детството влияят на настоящите ни
							връзки.
						</li>
						<li>- Практика за свързване с вътрешното дете.</li>
					</ul>
				</p>
				<p className="singlePostDesc pb-5">
					2.
					<b>Преодоляване на емоционалната зависимост:</b>
					<ul>
						<li>- Как да се освободим от нуждата от одобрение.</li>
						<li>- Изграждане на независимост и самоуважение.</li>
					</ul>
				</p>
				<p className="singlePostDesc pb-5">
					3.<b>Пренаписване на личната история:</b>
					<ul>
						<li>
							От жертва към създател: Промяна на личния наратив.
						</li>
					</ul>
				</p>
				<p className="singlePostDesc pb-5">
					<b>Практически упражнения:</b>
					<ul>
						<li>- Журналинг: Писмо до вътрешното дете.</li>
						<li>
							- Практика: "Кои са моите незадоволени нужди и как
							мога да ги задоволя сама?"
						</li>
					</ul>
				</p>

				<p className="singlePostDesc pb-5">
					<b>Модул 4: Ритуали за освобождение и ново начало</b>
				</p>
				<p className="singlePostDesc pb-5">
					<b>Тема:</b> Създаване на здрави взаимоотношения и връзка
					със себе си.
				</p>
				<p className="singlePostDesc pb-5">
					<b>Цели:</b>
					<ul>
						<li>- Финално освобождаване от токсичните енергии.</li>
						<li>
							- Изграждане на нови вярвания за любов и
							взаимоотношения.
						</li>
						<li>- Създаване на визия за здравословен живот.</li>
					</ul>
				</p>

				<p className="singlePostDesc pb-5">
					<b>Съдържание:</b>
					1. <b>Ритуали за освобождение:</b>
					<ul>
						<li>- Прощаване на себе си и партньора.</li>
						<li>- Ритуал за енергийно изчистване.</li>
					</ul>
				</p>
				<p className="singlePostDesc pb-5">
					2.
					<b>Привличане на здравословна връзка:</b>
					<ul>
						<li>
							- Как да разпознаем и привлечем подходящ партньор.
						</li>
						<li>- Нови вярвания за любов и взаимоотношения.</li>
					</ul>
				</p>
				<p className="singlePostDesc pb-5">
					3.<b>Изграждане на бъдеще със смисъл:</b>
					<ul>
						<li>Създаване на план за действие.</li>
						<li>
							Изграждане на визия за бъдещи отношения и личностно
							развитие.
						</li>
					</ul>
				</p>
				<p className="singlePostDesc pb-5">
					<b>Практически упражнения:</b>
					<ul>
						<li>- Ритуал: "Прощавам и се освобождавам."</li>
						<li>
							- Създаване на личен манифест за любов и
							взаимоотношения.
						</li>
						<li>
							- Медитация: Привличане на любов и вътрешен мир.
						</li>
					</ul>
				</p>

				<p className="singlePostDesc pb-5">
					<b>Бонуси:</b>
					<ul>
						<li>
							- <b>Видео лекции:</b>Обяснителни сесии за всяка
							тема.
						</li>
						<li>
							- <b>Работни тетрадки:</b>Подробни задания и въпроси
							за рефлексия.
						</li>
						<li>
							- <b>Групова среща:</b>Онлайн среща за подкрепа и
							споделяне с други участници.
						</li>
						<li>
							- <b>Водени медитации:</b>Записи, които да се
							използват ежедневно.
						</li>
					</ul>
				</p>

				<p className="singlePostDesc pb-5">
					<b>Краен резултат:</b>
				</p>
				<p className="singlePostDesc pb-5">
					Участниците ще се научат да:
					<ul>
						<li>
							- Разбират причините за своите връзки с нарцистични
							партньори.
						</li>
						<li>
							- Освобождават емоционалните травми и изграждат
							нови, здравословни вярвания.
						</li>
						<li>
							- Привличат хармонични връзки и водят пълноценен
							живот.
						</li>
					</ul>
				</p>
			</>
		),
		programImage: programImage2,
	},

	{
		id: "chakra-detox",
		programName: `Онлайн програма: "Детокс за 7-те чакри"`,
		programShortName: "Детокс за 7-те чакри",
		programShortDesc:
			"Детокс програмата за 7-те чакри е разработена на база балансирано хранене, съобразено с енергийните центрове на тялото. Целта е да изчистите токсините от тялото, да се свържете с вътрешния си свят и да събудите жизнената си енергия. Програмата е подходяща за всички, които искат да съчетаят физическо, емоционално и духовно здраве.",
		programDescription: (
			<>
				<p className="singlePostDesc pb-5">
					<b>Описание на програмата</b>
				</p>
				<p className="singlePostDesc pb-5">
					Детокс програмата за 7-те чакри е разработена на база
					балансирано хранене, съобразено с енергийните центрове на
					тялото. Целта е да изчистите токсините от тялото, да се
					свържете с вътрешния си свят и да събудите жизнената си
					енергия. Програмата е подходяща за всички, които искат да
					съчетаят физическо, емоционално и духовно здраве.
				</p>
				<p className="singlePostDesc pb-5">
					<b>Цена:</b> 50 лева на месец
				</p>
				<p className="singlePostDesc pb-5">
					<b>Начин на провеждане:</b> Онлайн, с достъп до рецепти,
					насоки и поддръжка.
				</p>
				<p className="singlePostDesc pb-5">
					<b>Какво включва програмата?</b>
					<ul>
						<li>
							1. <b>Седмично меню</b>, базирано на чакрите, с
							рецепти за всеки ден.
						</li>
						<li>
							2. <b>Видео лекции</b> за всяка чакра – нейното
							значение и начин на балансиране.
						</li>
						<li>
							3. <b>Практически насоки</b> за включване на
							цветове, аромати и медитации към ежедневието.
						</li>
						<li>
							4. <b>Чат група</b> за подкрепа и въпроси.
						</li>
					</ul>
				</p>
				<p className="singlePostDesc pb-5">
					<b>Структура на програмата</b>
				</p>
				<p className="singlePostDesc pb-5">
					<b>Ден 1: Коренна чакра (Мулатхара)</b>
					<ul>
						<li>
							- <b>Фокус:</b> Стабилност, сигурност и заземяване.
						</li>
						<li>
							- <b>Храни:</b>Червени храни (цвекло, червена чушка,
							домати), кореноплодни зеленчуци, протеини.
						</li>
					</ul>
				</p>

				<p className="singlePostDesc pb-5">
					<b>Ден 2: Сакрална чакра (Свадхищхана)</b>
					<ul>
						<li>
							- <b>Фокус:</b> Креативност, емоции и удоволствие.
						</li>
						<li>
							- <b>Храни:</b>Оранжеви храни (тиква, моркови,
							портокали), ядки и семена.
						</li>
					</ul>
				</p>

				<p className="singlePostDesc pb-5">
					<b>Ден 3: Слънчев сплит (Манипура)</b>
					<ul>
						<li>
							- <b>Фокус:</b> Воля, енергия и увереност.
						</li>
						<li>
							- <b>Храни:</b>Жълти храни (царевица, лимони, жълта
							чушка), зърнени храни.
						</li>
					</ul>
				</p>

				<p className="singlePostDesc pb-5">
					<b>Ден 4: Сърдечна чакра (Анахата)</b>
					<ul>
						<li>
							- <b>Фокус:</b> Любов, баланс и състрадание.
						</li>
						<li>
							- <b>Храни:</b> Зелени храни (спанак, броколи,
							авокадо), билки.
						</li>
					</ul>
				</p>

				<p className="singlePostDesc pb-5">
					<b>Ден 5: Гърлена чакра (Вишудха)</b>
					<ul>
						<li>
							- <b>Фокус:</b> Комуникация и изразяване.
						</li>
						<li>
							- <b>Храни:</b> Сини и лилави храни (боровинки,
							къпини, сливи), течности.
						</li>
					</ul>
				</p>

				<p className="singlePostDesc pb-5">
					<b>Ден 6: Чакра на третото око (Аджна)</b>
					<ul>
						<li>
							- <b>Фокус:</b> Интуиция и яснота.
						</li>
						<li>
							- <b>Храни:</b> Тъмносини и лилави храни (патладжан,
							сини сливи), здравословни мазнини.
						</li>
					</ul>
				</p>

				<p className="singlePostDesc pb-5">
					<b>Ден 7: Коронна чакра (Сахасрара)</b>
					<ul>
						<li>
							- <b>Фокус:</b> Връзка с висшето съзнание.
						</li>
						<li>
							- <b>Храни:</b> Леки храни, чайове, постене (по
							избор).
						</li>
					</ul>
				</p>

				<p className="singlePostDesc pb-5">
					<b>Допълнителни ресурси:</b>
					<ul>
						<li>
							- Медитации за всяка чакра (достъпни като аудио
							файлове).
						</li>
						<li>- Личен дневник за проследяване на процеса.</li>
						<li>- Списъци за пазаруване за всяка седмица.</li>
					</ul>
				</p>

				<p className="singlePostDesc pb-5">
					<b>Как да се включите?</b>
					<ul>
						<li>1. Регистрирайте се на сайта ни.</li>
						<li>2. Заплатете месечната такса от 50 лева.</li>
						<li>
							3. Получете достъп до платформата с всички
							материали.
						</li>
					</ul>
				</p>

				<p className="singlePostDesc pb-5">
					Присъединете се към "Детокс за 7-те чакри" и трансформирайте
					енергията и живота си още днес!
				</p>
			</>
		),
		programImage: programImage3,
	},
];

export { programInfo };
