import "../styles/posts.css";
import image from "../../../assets/images/motherFatherSon.jpg";
import { Helmet } from "react-helmet";

export default function MotherFatherSon() {
	return (
		<div className="singlePost">
			<div className="singlePostWrapper">
				<img src={image} alt="break-up" className="singlePostImg" />
				<h1 className="singlePostTitle">Майка, баща и син</h1>
				<Helmet>
					<title>Майка, баща и син</title>
					<meta
						name="description"
						content="Наскоро сина ми ме помоли да му помогна с написването на есе за
						училище. Темата беше свързана с бащинството и ролята на бащата в
						обществото. За мен беше истинско предизвикателство в живота да
						запазя авторитета и да дам ролята, която следва да има бащата на
						едно дете, особено момче в неговия живот."
					/>
				</Helmet>
				<div className="singlePostDesc">
					<p>
						Наскоро сина ми ме помоли да му помогна с написването на есе за
						училище. Темата беше свързана с бащинството и ролята на бащата в
						обществото. За мен беше истинско предизвикателство в живота да
						запазя авторитета и да дам ролята, която следва да има бащата на
						едно дете, особено момче в неговия живот.
					</p>
					<p>
						Като майка на момче, което отглеждам без постоянното присъствие на
						баща му, мога да кажа, че оставането в ролята на майка, без да
						заемем и ролята на баща, беше изключително трудна. Но това не беше
						най – сложната задача. По – сложно беше да запазя авторитета,
						уважението и институцията, която носи бащата в живота на едно дете.
					</p>
					<p>
						Раздялата с бащата на сина ми беше нелека и включваше в себе си
						всички аспекти на една раздяла, както и нейните фази. Ние не
						позволихме нашите отношения да повлияят на детето. Пред детето не са
						се водили спорове, нито някога е ползван за преносител на информация
						или арбитър. По – големия проблем беше в това, че живота на бащата
						на моят син, в онзи момент не беше за пример. Той беше тръгнал по
						път на зависимости, разруха и много негативизъм. Именно в този
						момент, аз осъзнах, че съм изправена пред два избора, единия беше да
						отделя детето осен физически и емоционално от баща му, а другия да
						се опитам да не разруша връзката между тях. Нивото на моето
						самосъзнание и развитие не беше на такова ниво, каквото е сега, но
						аз знаех, че бащата на сина ми е ТОЙ и нямам право да се опитвам да
						ги разделям. В последствие си обясних това, което отстоявах, а не
						знаех точно защо. разбира се това беше моята ситуация, при
						положение, че все пак баща му имаше желание да присъства в живота
						му. При неналичен баща, тази ситуация би могла да бъде отработена по
						друг начин.
					</p>
					<p>
						Когато едно момче има забрана да се доближи до своя баща, той
						чувства забрана и да се доближи към своята мъжественост. В такива
						момчета често надделява женската енергия. Това е една от причините
						синът да бъде подтикнат към хомосексуалност - забраненият достъп до
						мъжествеността. Забраната на достъп до бащата кара момчето, живеещо
						при майка си, да се откаже от мъжката си страна, да изостави
						мъжествеността.
					</p>
					<p className="pt-10">Посланието на майка му към бащата е жестоко:</p>
					<p>“Баща ти не става. Ти не е добре да си като него." </p>
					<p>
						Детето разбира това като че не е добре да бъде мъж. Защото живее с
						мама, не иска да й противоречи, животът му зависи от нея, то се
						съгласява, неосъзнато, разбира се, казва:{" "}
					</p>
					<p>“Да, мамо. Няма да съм мъж. Мъжете не стават." </p>
					<p className="pt-10">
						Тази картина може да се разшири много. Една неправилна стъпка от моя
						страна тогава, щеше да доведе след себе си неподозирани последствия
						в годините, можеше да определя целия му живот. А, за жалост,
						неосъзнати и въвлечени в историите, идващи от собствените ни рождени
						семейства, ние често правим грешки. Някои костват буквално животите
						на децата ни.
					</p>
					<p>
						Никога няма да забравя, как взех сина си по спешност от баща му и в
						какво състояние го намерих. Не беше за първи път. Детето се качи в
						колата и през сълзи ми каза: „ не искам повече да го виждам“, беше
						едва на 10 години. Най – големия ми страх се беше сбъднал, сина ми
						преживяваше това, което бях преживяла аз, още повече това, от което
						си бях тръгнала. Как мислите? Какво казах тогава? Събрах цялата си
						сила, разум и вяра и му казах: „ Това е твоя баща. Той не е лош, той
						е болен и объркан. Само с любов и подкрепа може да се излекува,
						макар и да му я даваме от разстояние, не трябва да го изоставяме.“
					</p>
					<p>
						Повярвайте ми, никой не ме разбираше. Дори партньора в живота ми
						тогава, който също е баща на момче, което не расте с него, дори той
						не можеше да ме разбере. Но аз, днес, се гордея със себе си. Защото
						сина ми расте като един достоен и истински мъж, който уважава майка
						си и баща си, въпреки нашия житейски път, и знае ще едната половинка
						от него е неговия баща, другата съм аз.
					</p>
					<p>
						Ако сте разделени с партньора си и имате нужда от насока за това как
						да постъпите с вашето дете.
					</p>
					<p>Ако имате проблем с бивш партньор и нямате достъп до детето си.</p>
					<p>
						Ако сте разкъсани от гняв и обида и не знаете как да простите и да
						приемете родителя на детето си в сърцето.
					</p>
					<p>Пишете ми и запазете час за консултация на живо.</p>
					<p>
						Тук отново ще повторя, че разликата между добрия коуч и
						изключителния такъв е в неговата опитност.
					</p>
					<p className="pt-10">Благодаря!</p>
				</div>
			</div>
		</div>
	);
}
