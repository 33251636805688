import { Link, useLocation, useNavigate } from "react-router-dom";
import "./topbar.css";
import logo from "../../assets/images/logo.jpg";
import { useState, useEffect, useRef } from "react";

export default function TopBar() {
	const [blogDropdownOpen, setBlogDropdownOpen] = useState(false);
	const [therapyDropdownOpen, setTherapyDropdownOpen] = useState(false);
	const [servicesDropdownOpen, setServicesDropdownOpen] = useState(false);
	const [hamburgerOpen, setHamburgerOpen] = useState(false);
	const blogDropdownRef = useRef(null);
	const therapyDropdownRef = useRef(null);
	const servicesDropdownRef = useRef(null);
	const hamburgerRef = useRef(null);

	const toggleBlogDropdown = () => {
		setBlogDropdownOpen(!blogDropdownOpen);
	};

	const toggleTherapyDropdown = () => {
		setTherapyDropdownOpen(!therapyDropdownOpen);
	};

	const toggleServicesDropdown = () => {
		setServicesDropdownOpen(!servicesDropdownOpen);
	};

	const toggleHamburger = () => {
		setHamburgerOpen(!hamburgerOpen);
	};

	const handleClickOutside = (event) => {
		if (
			blogDropdownRef.current &&
			!blogDropdownRef.current.contains(event.target)
		) {
			setBlogDropdownOpen(false);
		}
		if (
			hamburgerRef.current &&
			!hamburgerRef.current.contains(event.target)
		) {
			setHamburgerOpen(false);
		}
		if (
			servicesDropdownRef.current &&
			!servicesDropdownRef.current.contains(event.target)
		) {
			setServicesDropdownOpen(false);
		}
		if (
			therapyDropdownRef.current &&
			!therapyDropdownRef.current.contains(event.target)
		) {
			setTherapyDropdownOpen(false);
		}
	};

	useEffect(() => {
		document.addEventListener("click", handleClickOutside);
		return () => {
			document.removeEventListener("click", handleClickOutside);
		};
	}, []);

	const [scrolled, setScrolled] = useState(false);

	useEffect(() => {
		const handleScroll = () => {
			setScrolled(window.scrollY > 50);
		};

		document.addEventListener("scroll", handleScroll);

		return () => {
			document.removeEventListener("scroll", handleScroll);
		};
	}, []);

	const { pathname } = useLocation();
	const navigate = useNavigate();

	const handleLinkClick = (to) => (event) => {
		event.preventDefault();

		if (to === pathname) {
			window.scrollTo(0, 0);
		} else {
			navigate(to);
		}
	};

	return (
		<>
			<div className="top">
				<div className="infoContainer">
					<div className="infoWrapper">
						<i className="contactIcon fa-solid fa-phone">
							<a href="tel:088 550 6682">+359 885 506 682</a>
						</i>
						<i className="contactIcon fa-solid fa-envelope">
							<a href="mailto:stanislava_mitova@yahoo.com">
								stanislava_mitova@yahoo.com
							</a>
						</i>
					</div>
				</div>
				<div className="topNav">
					<div
						className={`topLeft ${
							scrolled ? "scrolledTopLeft" : ""
						}`}
					>
						<Link
							className="link excludeHover"
							to="/"
							onClick={handleLinkClick("/")}
						>
							<img className="topImg" src={logo} alt="logo" />
						</Link>
						<span className="person">Станислава Митова</span>
						<span className="profession">
							Психолог и енергиен коуч
						</span>
					</div>
					<div className="topCenter">
						<div className="hamburgerMenu">
							<i
								ref={hamburgerRef}
								onClick={toggleHamburger}
								className="fa fa-bars"
								aria-hidden="true"
							></i>
							{hamburgerOpen && (
								<ul className="hamburgerList">
									<li className="hamburgerMenuItemSectionName">
										<Link
											className="link"
											to="/"
											onClick={handleLinkClick("/")}
										>
											НАЧАЛО
										</Link>
									</li>
									<li className="hamburgerMenuItemSectionName">
										<Link
											className="link"
											to="/about"
											onClick={handleLinkClick("/about")}
										>
											ЗА МЕН
										</Link>
									</li>
									<li className="hamburgerMenuItemSectionName">
										МОИТЕ УСЛУГИ
									</li>
									<li className="hamburgerMenuItem">
										<Link
											className="link"
											to="/personal-coaching-therapy"
											onClick={handleLinkClick(
												"/personal-coaching-therapy"
											)}
										>
											Индивидуална коучинг терапия
										</Link>
									</li>
									<li className="hamburgerMenuItem">
										<Link
											className="link"
											to="/energy-coaching"
											onClick={handleLinkClick(
												"/energy-coaching"
											)}
										>
											Енергийна коучинг сесия
										</Link>
									</li>
									<li className="hamburgerMenuItem">
										<Link
											className="link"
											to="/company-consulting"
											onClick={handleLinkClick(
												"/company-consulting"
											)}
										>
											Корпоративно консултиране
										</Link>
									</li>
									<li className="hamburgerMenuItem">
										<Link
											className="link"
											to="/positive-energy-manager"
											onClick={handleLinkClick(
												"/positive-energy-manager"
											)}
										>
											Positive Energy Manager в корпорация
										</Link>
									</li>
									<li className="hamburgerMenuItem">
										<Link
											className="link"
											to="/startup-coaching"
											onClick={handleLinkClick(
												"/startup-coaching"
											)}
										>
											Start up коучинг сесия
										</Link>
									</li>

									<li className="hamburgerMenuItem">
										<Link
											className="link"
											to="/leadership-coaching"
											onClick={handleLinkClick(
												"/leadership-coaching"
											)}
										>
											Leadership коучинг сесия
										</Link>
									</li>
									<li className="hamburgerMenuItem">
										<Link
											className="link"
											to="/reiki"
											onClick={handleLinkClick("/reiki")}
										>
											Рейки
										</Link>
									</li>
									<li className="hamburgerMenuItem">
										<Link
											className="link"
											to="/soul-detective"
											onClick={handleLinkClick(
												"/soul-detective"
											)}
										>
											Soul Detective
										</Link>
									</li>
									<li className="hamburgerMenuItem">
										<Link
											className="link"
											to="/inner-child-healing"
											onClick={handleLinkClick(
												"/inner-child-healing"
											)}
										>
											Лечение на вътрешното ни дете
										</Link>
									</li>
									{/* <li className="hamburgerMenuItem	">
										<Link
											className="link"
											to="/taro"
											onClick={handleLinkClick("/taro")}
										>
											Таро консултация
										</Link>
									</li> */}
									{/* <li className="hamburgerMenuItem">
										<Link
											className="link"
											to="/astrology"
											onClick={handleLinkClick(
												"/astrology"
											)}
										>
											Кармична астрология
										</Link>
									</li> */}
									<li className="hamburgerMenuItemSectionName">
										<Link
											className="link"
											to="/programs"
											onClick={handleLinkClick(
												"/programs"
											)}
										>
											ПРОГРАМИ
										</Link>
									</li>
									<li className="hamburgerMenuItemSectionName">
										<Link
											className="link"
											to="/events"
											onClick={handleLinkClick("/events")}
										>
											СЪБИТИЯ
										</Link>
									</li>
									<li className="hamburgerMenuItemSectionName">
										<Link
											className="link"
											to="/gallery"
											onClick={handleLinkClick(
												"/gallery"
											)}
										>
											ГАЛЕРИЯ
										</Link>
									</li>
									<li className="hamburgerMenuItemSectionName">
										<Link
											className="link"
											to="/prices"
											onClick={handleLinkClick("/prices")}
										>
											ЦЕНИ
										</Link>
									</li>
									<li className="hamburgerMenuItemSectionName">
										БЛОГ
									</li>
									<li className="hamburgerMenuItem">
										<Link
											className="link"
											to="/forbes-interview"
											onClick={handleLinkClick(
												"/forbes-interview"
											)}
										>
											Forbes
										</Link>
									</li>
									<li className="hamburgerMenuItem">
										<Link
											className="link"
											to="/break-up"
											onClick={handleLinkClick(
												"/break-up"
											)}
										>
											Раздялата за мен
										</Link>
									</li>
									<li className="hamburgerMenuItem">
										<Link
											className="link"
											to="/my-path"
											onClick={handleLinkClick(
												"/my-path"
											)}
										>
											Моят личен път
										</Link>
									</li>
									<li className="hamburgerMenuItem">
										<Link
											className="link"
											to="/my-femine-path"
											onClick={handleLinkClick(
												"/my-femine-path"
											)}
										>
											Моят път към женската ми същност
										</Link>
									</li>
									<li className="hamburgerMenuItem">
										<Link
											className="link"
											to="/mother-father-son"
											onClick={handleLinkClick(
												"/mother-father-son"
											)}
										>
											Майка, баща и син
										</Link>
									</li>
									<li className="hamburgerMenuItem">
										<Link
											className="link"
											to="/taro-and-therapy"
											onClick={handleLinkClick(
												"/taro-and-therapy"
											)}
										>
											Таро и терапия
										</Link>
									</li>
								</ul>
							)}
						</div>
						<ul className="topList ">
							<li className="topListItem">
								<Link
									className="link"
									to="/"
									onClick={handleLinkClick("/")}
								>
									<span>НАЧАЛО</span>
								</Link>
							</li>
							<li className="topListItem">
								<Link
									className="link"
									to="/about"
									onClick={handleLinkClick("/about")}
								>
									<span>ЗА МЕН</span>
								</Link>
							</li>
							<li
								className="topListItem dropdown"
								onClick={toggleServicesDropdown}
								ref={servicesDropdownRef}
							>
								<span>МОИТЕ УСЛУГИ</span>
								<i
									className={`dropdownIcon ${
										servicesDropdownOpen ? "rotate180" : ""
									} fa-solid fa-caret-down`}
								></i>
								<ul
									className={`dropdownMenu ${
										servicesDropdownOpen ? "visible" : ""
									}`}
								>
									<li className="dropdownMenuItem">
										<Link
											className="link"
											to="/personal-coaching-therapy"
											onClick={handleLinkClick(
												"/personal-coaching-therapy"
											)}
										>
											Индивидуална коучинг терапия
										</Link>
									</li>
									<li className="dropdownMenuItem">
										<Link
											className="link"
											to="/energy-coaching"
											onClick={handleLinkClick(
												"/energy-coaching"
											)}
										>
											Енергийна коучинг сесия
										</Link>
									</li>
									<li className="dropdownMenuItem">
										<Link
											className="link"
											to="/company-consulting"
											onClick={handleLinkClick(
												"/company-consulting"
											)}
										>
											Корпоративно консултиране
										</Link>
									</li>
									<li className="dropdownMenuItem">
										<Link
											className="link"
											to="/positive-energy-manager"
											onClick={handleLinkClick(
												"/positive-energy-manager"
											)}
										>
											Positive Energy Manager в корпорация
										</Link>
									</li>
									<li className="dropdownMenuItem">
										<Link
											className="link"
											to="/startup-coaching"
											onClick={handleLinkClick(
												"/startup-coaching"
											)}
										>
											Start up коучинг сесия
										</Link>
									</li>
									<li className="dropdownMenuItem">
										<Link
											className="link"
											to="/leadership-coaching"
											onClick={handleLinkClick(
												"/leadership-coaching"
											)}
										>
											Leadership коучинг сесия
										</Link>
									</li>
									<li className="dropdownMenuItem">
										<Link
											className="link"
											to="/reiki"
											onClick={handleLinkClick("/reiki")}
										>
											Рейки
										</Link>
									</li>
									<li className="dropdownMenuItem">
										<Link
											className="link"
											to="/soul-detective"
											onClick={handleLinkClick(
												"/soul-detective"
											)}
										>
											Soul Detective
										</Link>
									</li>
									<li className="dropdownMenuItem">
										<Link
											className="link"
											to="/inner-child-healing"
											onClick={handleLinkClick(
												"/inner-child-healing"
											)}
										>
											Лечение на вътрешното ни дете
										</Link>
									</li>
									{/* <li className="dropdownMenuItem">
										<Link
											className="link"
											to="/taro"
											onClick={handleLinkClick("/taro")}
										>
											Таро консултация
										</Link>
									</li> */}
									{/* <li className="dropdownMenuItem">
										<Link
											className="link"
											to="/astrology"
											onClick={handleLinkClick(
												"/astrology"
											)}
										>
											Кармична астрология
										</Link>
									</li> */}
								</ul>
							</li>
							<li className="topListItem">
								<Link
									className="link"
									to="/programs"
									onClick={handleLinkClick("/programs")}
								>
									<span>ПРОГРАМИ</span>
								</Link>
							</li>
							<li className="topListItem">
								<Link
									className="link"
									to="/events"
									onClick={handleLinkClick("/events")}
								>
									<span>СЪБИТИЯ</span>
								</Link>
							</li>
							<li className="topListItem">
								<Link
									className="link"
									to="/gallery"
									onClick={handleLinkClick("/gallery")}
								>
									<span>ГАЛЕРИЯ</span>
								</Link>
							</li>
							<li className="topListItem">
								<Link
									className="link"
									to="/prices"
									onClick={handleLinkClick("/prices")}
								>
									<span>ЦЕНИ</span>
								</Link>
							</li>
							<li
								className="topListItem dropdown"
								onClick={toggleBlogDropdown}
								ref={blogDropdownRef}
							>
								<span>БЛОГ</span>
								<i
									className={`dropdownIcon ${
										blogDropdownOpen ? "rotate180" : ""
									} fa-solid fa-caret-down`}
								></i>
								<ul
									className={`dropdownMenu ${
										blogDropdownOpen ? "visible" : ""
									}`}
								>
									{/* Add more dropdown items as needed */}
									<li className="dropdownMenuItem">
										<Link
											className="link"
											to="/forbes-interview"
											onClick={handleLinkClick(
												"/forbes-interview"
											)}
										>
											Forbes
										</Link>
									</li>
									<li className="dropdownMenuItem">
										<Link
											className="link"
											to="/break-up"
											onClick={handleLinkClick(
												"/break-up"
											)}
										>
											Раздялата за мен
										</Link>
									</li>
									<li className="dropdownMenuItem">
										<Link
											className="link"
											to="/my-path"
											onClick={handleLinkClick(
												"/my-path"
											)}
										>
											Моят личен път
										</Link>
									</li>
									<li className="dropdownMenuItem">
										<Link
											className="link"
											to="/my-femine-path"
											onClick={handleLinkClick(
												"/my-femine-path"
											)}
										>
											Моят път към женската ми същност
										</Link>
									</li>
									<li className="dropdownMenuItem">
										<Link
											className="link"
											to="/mother-father-son"
											onClick={handleLinkClick(
												"/mother-father-son"
											)}
										>
											Майка, баща и син
										</Link>
									</li>
									<li className="dropdownMenuItem">
										<Link
											className="link"
											to="/taro-and-therapy"
											onClick={handleLinkClick(
												"/taro-and-therapy"
											)}
										>
											Таро и терапия
										</Link>
									</li>
								</ul>
							</li>
						</ul>
					</div>
					<div className="topRight">
						<a
							href="https://www.facebook.com/soulmindhealth"
							target="_blank"
							rel="noreferrer"
						>
							<i className="topIcon fa-brands fa-square-facebook"></i>
						</a>
						<a
							href="https://www.instagram.com/stanislava_mitova_/"
							target="_blank"
							rel="noreferrer"
						>
							<i className="topIcon fa-brands fa-instagram"></i>
						</a>
					</div>
				</div>
			</div>
		</>
	);
}
