import "../styles/posts.css";
import image1 from "../../../assets/images/taro-and-therapy1.jpg";
import image2 from "../../../assets/images/taro-and-therapy2.jpg";
import image3 from "../../../assets/images/taro-and-therapy3.png";
import { Helmet } from "react-helmet";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDiamond } from "@fortawesome/free-solid-svg-icons";

export default function TaroAndTherapy() {
	return (
		<div className="singlePost">
			<div className="singlePostWrapper">
				<img
					src={image1}
					alt="taro-therapist"
					className="singlePostImg"
				/>
				<img src={image2} alt="taro-cards" className="singlePostImg" />
				<h1 className="singlePostTitle">
					Психотерапия с рождените карти таро
				</h1>
				<Helmet>
					<title>Психотерапия с рождените карти таро</title>
					<meta
						name="description"
						content="Като практикуващ психолог и коуч, винаги търся точни методи, които биха ми помогнали бързо да опозная клиентите си. Често успехът на терапията зависи от това колко добре и бързо успея да разбера визията на моя клиент за живота. През последните три години експериментирах с методите „Психология на Таро“ на Уолт и Рут Ан Амбърстоун; по-специално 'Континуумът на рождените карти', 'Елементният масив' и 'Разпространението на скритото лице'."
					/>
				</Helmet>

				<div className="singlePostDesc">
					<p>
						Като практикуващ психолог и коуч, винаги търся точни
						методи, които биха ми помогнали бързо да опозная
						клиентите си. Често успехът на терапията зависи от това
						колко добре и бързо успея да разбера визията на моя
						клиент за живота. През последните три години
						експериментирах с методите „Психология на Таро“ на Уолт
						и Рут Ан Амбърстоун; по-специално "Континуумът на
						рождените карти", "Елементният масив" и
						"Разпространението на скритото лице". Намерих ги за
						необичайно полезни начини за бързо получаване на много
						точна информация. Нещо повече, клиентите ми бяха
						очаровани и се забавляваха от нещата, които успях да им
						кажа за тях въз основа на тези методи.
					</p>
					<p>
						Моята цел е да интегрирам таро в психологията, за да
						разработя нова форма на психотерапия, която е солидно
						базирана на прозренията за нас, които таро може да
						предложи. В най-добрия случай психотерапията на таро, ще
						постави личната терапия на човека в по-широкия контекст
						на духовната му еволюция. Днес ще опиша как запознавам
						клиентите си с таро, като изчислявам и им обяснявам
						техните карти за раждане. Разбира се, тези методи могат
						да бъдат адаптирани, за да подобрят всяко четене на таро
						или да подпомогнат личното израстване.
					</p>
					<p className="pt-10 pb-10 bold">
						<FontAwesomeIcon icon={faDiamond} className="icon" />
						Ползите от работата с рождени карти в психотерапията или
						в коучинг терапиите
					</p>
					<p>
						Едно от основните предимства на илюстрирането на нечии
						житейски проблеми с картите на раждане е, че намалява
						чувството на срам, което много хора изпитват, когато
						говорят за недостатъците, които са възприели като свои.
						Основното в Психологията на Таро е, че всеки се бори с
						проблемите на своите рождени карти и че всички са равни
						в това отношение. Освен това, тъй като различните двойки
						карти имат различни проблеми и стилове на личността,
						свързани с тях, всяка система на Таро Психотерапия,
						която се развива от Таро Психологията, автоматично
						разпознава, че различните хора се нуждаят от различни
						подходи.
					</p>
					<p>
						Рождените карти и тяхното влияние, предполага, че ние се
						прераждаме отново и отново и че в крайна сметка всеки от
						нас работи върху проблемите на всяка двойка или тройка
						рождени карти, докато се развиваме както като личност,
						така и духовно. Това автоматично добавя духовно
						измерение към психотерапевтичната работа и ни позволява
						да разглеждаме психотерапията като само един аспект от
						общата ни еволюция като духовни същества. Моето мнение
						е, че трябва да имаме работещо его, преди да можем
						успешно да го преодолеем. Или, както бих могла да го
						кажа чрез термините на Таро Психотерапията, трябва да се
						почувстваме приключили с проблемите на този свят (Малкут
						в Кабалистичното Таро и областта на пентаклите или
						Земята), преди да тръгнем по пътя към завръщането ни към
						нашия божествен източник.
					</p>
					<p className="pt-10 pb-10 bold">
						<FontAwesomeIcon icon={faDiamond} className="icon" />
						Представяне на карти за раждане на клиенти
					</p>
					<p>
						Повечето от моите клиенти не са запознати с таро и
						първоначално са изненадани и дори скептични, че картите
						могат да се използват за друго освен за "гадаене".
						Колодата таро, която използвам в сесия е колодата на
						Райдър-Уайт-Смит, която еволюирала от пред
						психоаналитична система за личностно израстване и черпи
						от редица духовни традиции. Обяснявам, че има начин, въз
						основа на датата им на раждане, да изберат две основни
						карти от арканите, които представляват вида проблеми на
						личното израстване, с които те ще се сблъскват отново и
						отново през този живот. Казвам им, че няма логична
						научна причина, която да знам, защо това числено
						намаление на рождените карти е приложима за тях (в края
						на краищата календарът е сравнително ново човешко
						изобретение); въпреки това открих, че тези карти са
						много полезни.
					</p>
					<p className="pt-10 pb-10 bold">
						<FontAwesomeIcon icon={faDiamond} className="icon" />
						Как да изчислим карти за раждане
					</p>
					<p>
						Ако клиентът ми прояви интерес, изчислявам
						неговите/нейните рождени карти, като събирам месеца,
						деня, годината на тяхното раждане заедно. В повечето
						случаи общата сума може да бъде намалена само до две
						основни числа на арканите, които след това стават
						рождена карта. Случва се човек да има дата на раждане,
						която се свежда до три числа, като слънце, колело,
						магьосник (19 = 10 = 1). Тези хора имат три важни
						аспекта от своите житейски уроци вместо обичайните два.
						За целите на работата на Birth Card Continuum Глупакът
						(номер 0 в колодата Rider-Waite-Smith) не се използва
						като карта за раждане, тъй като се приема, че всички сме
						еднакво „Глупави“. Например, ако клиентът ми е роден на
						8 юли 1978 г.; Събирам 7 + 8 + 19 + 78, за да получа
						112. След това добавям първите две цифри, 11, към
						третата цифра, 2, за общо 13. След това добавям 1 + 3 и
						получавам 4. Това ми дава двата основни аркани, които
						съответстват на тези ключови числа: 13 = Смърт и 4 =
						Императорът.
					</p>
					<p className="pt-10 pb-10 bold">
						<FontAwesomeIcon icon={faDiamond} className="icon" />
						Как използвам Birth Card Continuum в психотерапията
					</p>
					<div className="singlePostWrapper pd-10">
						<img
							src={image3}
							alt="taro card"
							className="singlePostImg img-width-auto"
						/>
						<div>
							<p>
								След като изчисля рождените карти на моя клиент,
								работя с тях, сякаш те представляват два края на
								едно пътуване. Човек с рождени карти „Император
								на смъртта“, например, вероятно ще трябва да
								работи върху проблеми, свързани с двете карти,
								но хората ще се различават по отношение на това
								къде в „пътуването“ ще бъдат техните основни
								проблеми. Към края на проблемите на картата
								Смърт са всички въпроси, които са свързани с
								освобождаването от старото и приемането на
								новото, приемането на неизбежността на промяната
								и трансформацията и постигането на мир със
								смъртта. По отношение на Императора, проблемите
								се отнасят до желанието на човек да поеме
								отговорност за другите и да се контролира,
								стабилизира, организира и бори за личното си
								„кралство“. Императорът се съпротивлява на всяка
								промяна, която той лично не планира и инициира,
								докато Смъртта представлява неустоима и
								неконтролируема сила, която трансформира всичко,
								до което се докосне.
							</p>
							<p>
								Предполага се, че хората, които имат тези
								рождени карти, работят върху житейски уроци,
								свързани с тези аспекти. Те може да са
								постигнали относителен напредък и контрол над
								единия край, страната на Императора, например,
								докато продължава да се бори с другия край,
								проблемите, свързани със Смъртта.
							</p>
							<p>
								Моята клиентка Ани ( давам произволно име )
								дойде при мен, защото беше в депресирана и
								изпитваше чувство на яд през по – голямата част
								от дните си. Имаше чувството, че тя е
								присъствена в живота на много хора, но в нейния
								живот, никой не беше до нея. Нещо повече, тя се
								чувстваше принудена да се намеси и да поеме
								контрола върху живота на другите хора и да ги
								спаси от това, което смяташе за сериозни грешки
								в техния живот – „спасителката“. Това я караше
								да се чувства изтощена и неоценена. Искаше да
								разбере защо не може да спре да ръководи всички
								тези „неблагодарни“ хора. Освен това Ани все още
								тъжеше за всички загуби, които беше претърпяла в
								живота си. Тя си спомняше всички предстоящи
								годишнини от смъртта на почти всеки, за когото
								някога се е интересувала и който е починал и
								изпитваше страх.
							</p>
							<p>
								След като чух оплакванията на Ани, изчислих
								нейните рождени карти и разбрах, че тя е
								„Император на смъртта“. Извадих тези две карти
								от тестето си, сложих ги с лицето нагоре пред
								нея и й разказах накратко какво означава всяка.
								Знаейки, че тя е „Император на смъртта“, бързо
								разбера, че много от нейните проблеми са напълно
								нормални, макар и малко крайни в нейния случай.
								Подобно на много императори, тя беше
								изключително компетентна и беше убедена, че
								нейният начин винаги е най-добрият, ако не и
								единственият подход към дадена ситуация. За мой
								късмет тя имаше и чувство за хумор. Каза ми:
								„Как мога да спра да бъда толкова властна и
								винаги да искам да поема контрола?“ Казах:
								„Никога! Винаги ще бъдеш властна, защото по
								природа си тип Император. Чувала ли си някога за
								император, който не управлява? Да не поема и
								носи отговорност?" Тя се засмя и се съгласи.
								Освен това отбелязах, че тя има затруднения с
								уроците на картата Смърт. Наистина й беше трудно
								да се откаже и да продължи напред. Предположих,
								че тъй като изглеждаше, че е овладяла
								Императорската страна на спектъра и нямаше
								проблем да поеме контрола, по-трудната
								терапевтична работа вероятно ще се случи около
								проблемите на трансформацията, движението напред
								и приемането на факта, че всичко има край.
								Всъщност, когато опознах Ани по-добре, и на
								двете ни стана ясно, че много от нейните опити
								да контролира другите идват от страха й, че те
								ще умрат и ще ги загуби, ако не се погрижи за
								здравето им и не помогне ги извадете финансово.
								Тя действаше като Император, за да не се налага
								да се справя със Смъртта.
							</p>
							<p>
								С напредването на терапията на Ани се връщахме
								отново и отново към нейните две рождени карти.
								Те се превърнаха в кратък начин да се говори за
								нейните проблеми и дадоха лице на нейните
								страхове и притеснения. Картата на Императора
								преоформи проблемите, които тя имаше с поемането
								на отговорност и управлението по такъв начин, че
								вместо да се срамува от тези характеристики, тя
								можеше да ги види като потенциални силни страни,
								с които можеше да се гордее, след като ги
								настрои фино.
							</p>
							<p>
								Николай, друг мой клиент с карти на „Императора
								на смъртта“, беше слаб и в двата края на „пътя“.
								Имал е травматично и насилствено детство, което
								го е карало да се страхува от конфронтация. За
								разлика от Ани, той не умееше да отстоява себе
								си пред другите хора и се оставяше да бъде
								тормозен от приятели и семейство. Въпреки това,
								той е изразил нуждата си от контрол, като станал
								финансово независим в много ранна възраст и си
								закупил собствен апартамент. Чувстваше се в
								безопасност само когато знаеше, че може да бъде
								напълно самодостатъчен в собственото си малко
								лично кралство.
							</p>
							<p>
								От страна на картата на смъртта, той се
								страхуваше от всяка предстояща промяна в живота
								си. Той не се съсредоточаваше върху минали
								загуби като Ани, но не можеше да си представи
								лесна и естествена трансформация. Въпреки че
								беше много способен и успешен, всеки нов
								житейски преход, като завършване на колеж или
								преместване в нов квартал, го караше да се
								тревожи силно. Подобно на Ани, Николай също беше
								много отговорен и изключително надежден; черти,
								които съм наблюдавала, че често вървят с типове
								Император. Информацията, че той е „Император на
								смъртта“, ми помогна да разбера нуждата му да
								контролира собствения си живот и трудностите му
								с преходите, които той смяташе, че са извън
								неговия контрол. Това също ми помогна да
								забележа, че въпреки че не беше изпъкващ сред
								приятелите си, той работеше работа, в която
								контролираше група хора и успяваше да постави
								така нещата, че те да изпълняват стриктно
								неговите препоръки. Разбирането, че Николай е
								„император на смъртта“, увеличи осъзнаването ми,
								че неговата неувереност е по-скоро резултат от
								малтретирането, което е претърпял като малко
								момче, отколкото отражение на естествения му
								темперамент. Докато работихме върху възвръщането
								му на Императорската страна във външния свят,
								той стана по-отстояващ в личния си живот и пое
								работа, която му позволи да поеме отговорността
								за собственото си пространство и да създаде
								своето работно царство.
							</p>
						</div>
					</div>
					<p className="pt-10 pb-10 bold">
						<FontAwesomeIcon icon={faDiamond} className="icon" />
						Заключение
					</p>
					<p>
						Таро и психотерапията изглеждат естествени партньори.
						Много от най-опитните читатели на таро вече използват
						картите, за да помогнат на своите клиенти по начини,
						които имат повече общо с психотерапията, отколкото с
						гадаенето. Книгите на Мери Гриър са отлични примери за
						тази тенденция; както, разбира се, методите на Уолт и
						Рут Ан Амбърстоун „Психология на Таро“. Тъй като
						споделях интереса си към таро с други психотерапевти,
						открих, че много от моите колеги също тихо четат карти
						таро и понякога ги използват по време на сесия. Това ми
						се струва подходящо време двете дисциплини да се срещнат
						официално и да обединят усилията си.
					</p>
				</div>
			</div>
		</div>
	);
}
