import Home from "./pages/home/Home";
import TopBar from "./components/topbar/TopBar";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import About from "./pages/about/About";
import Contact from "./pages/contact/Contact";
import Reiki from "./components/posts/reiki/Reiki";
// import Taro from "./components/posts/taro/Taro";
import SoulDetective from "./components/posts/soulDetective/SoulDetective";
import InnerChildHealing from "./components/posts/innerChildHealing/InnerChildHealing";
import "@fortawesome/fontawesome-free/css/all.min.css";
import Footer from "./components/footer/Footer";
import { useEffect } from "react";
import BreakUp from "./components/posts/break-up/breakUp";
// import Astrology from "./components/posts/astrology/astrology";
import CompanyConsulting from "./components/posts/company-consulting/companyConsulting";
import EnergyCoaching from "./components/posts/energy-coaching/energyCoaching";
import MyPath from "./components/posts/myPath/myPath";
import StartupCoaching from "./components/posts/startup-coaching/startup-coaching";
import PersonalCoachingTherapy from "./components/posts/personoal-coaching-therapy/personal-coaching-therapy";
import LeadershipCoaching from "./components/posts/leadership-coaching/leadershipCoaching";
import ForbesInterview from "./components/posts/forbes-interview/forbesInterview";
import MyFeminePath from "./components/posts/myFeminePath/myFeminePath";
import MotherFatherSon from "./components/posts/motherFatherSon/motherFatherSon";
import { Helmet } from "react-helmet";
import Gallery from "./components/posts/gallery/gallery";
import Events from "./components/posts/events/events";
import SingleEvent from "./components/posts/events/singleEvent/singleEvent";
import SingleGallery from "./components/posts/gallery/singleGallery/singleGallery";
import TaroAndTherapy from "./components/posts/taroAndTherapy/taroAndTherapy";
import Programs from "./components/posts/programs/programs";
import Prices from "./components/posts/prices/Prices";
import SingleProgram from "./components/posts/programs/singleProgram/singleProgram";
import PositiveEnergyManager from "./components/posts/positive-energy-manager/positiveEnergyManager";

function ScrollToTopOnRouteChange() {
	const { pathname } = useLocation();

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [pathname]);

	return null;
}

function App() {
	return (
		<BrowserRouter>
			<ScrollToTopOnRouteChange />
			<TopBar />
			<Helmet>
				<title>Станислава Митова - Психолог и Енергиен коуч</title>
				<meta
					name="description"
					content="Психологични и коучинг терапии"
				/>
				<meta name="keywords" content="Психолог, Коуч, Рейки" />
			</Helmet>
			<Routes>
				<Route exact path="/" element={<Home />} />
				<Route path="/contact" element={<Contact />} />
				<Route path="/about" element={<About />} />
				<Route path="/reiki" element={<Reiki />} />
				<Route path="/prices" element={<Prices />} />
				{/* <Route path="/taro" element={<Taro />} /> */}
				{/* <Route path="/astrology" element={<Astrology />} /> */}
				<Route path="/soul-detective" element={<SoulDetective />} />
				<Route
					path="/inner-child-healing"
					element={<InnerChildHealing />}
				/>
				<Route path="/break-up" element={<BreakUp />} />
				<Route path="/my-path" element={<MyPath />} />
				<Route path="/my-femine-path" element={<MyFeminePath />} />
				<Route
					path="/company-consulting"
					element={<CompanyConsulting />}
				/>
				<Route
					path="/positive-energy-manager"
					element={<PositiveEnergyManager />}
				/>
				<Route path="/programs" element={<Programs />} />
				<Route
					path="/programs/:programId"
					element={<SingleProgram />}
				/>
				<Route path="/energy-coaching" element={<EnergyCoaching />} />
				<Route path="/startup-coaching" element={<StartupCoaching />} />
				<Route
					path="/personal-coaching-therapy"
					element={<PersonalCoachingTherapy />}
				/>
				<Route
					path="/leadership-coaching"
					element={<LeadershipCoaching />}
				/>
				<Route path="/forbes-interview" element={<ForbesInterview />} />
				<Route path="/taro-and-therapy" element={<TaroAndTherapy />} />
				<Route
					path="/mother-father-son"
					element={<MotherFatherSon />}
				/>
				<Route path="/events" element={<Events />} />
				<Route path="/events/:eventId" element={<SingleEvent />} />
				<Route path="/gallery" element={<Gallery />} />
				<Route path="/gallery/:galleryId" element={<SingleGallery />} />
				{/* <Route path="/psychology-consulting" element={<PsychologyConsulting />} /> */}
			</Routes>
			<Footer />
		</BrowserRouter>
	);
}

export default App;
