import "../styles/posts.css";
import image1 from "../../../assets/images/stanislava_forbes_1.jpg";
import image2 from "../../../assets/images/stanislava_forbes_2.jpg";
import { Helmet } from "react-helmet";

export default function ForbesInterview() {
	return (
		<div className="singlePost">
			<div className="singlePostWrapper">
				<div className="image-wrapper">
					<img src={image1} alt="forbes interview" className="singlePostImg" />
					<img
						src={image2}
						alt="forbes scene interview"
						className="singlePostImg"
					/>
				</div>
				<h1 className="singlePostTitle">Forbes</h1>
				<Helmet>
					<title>Forbes</title>
					<meta
						name="description"
						content="Скъпи приятели и последователи, повечето от вас разбраха за
						участието ми във Forbes Woman Forum, Sky is the limit, което се
						проведе на 07.11.2023г. в София, хотел Хилтън. За мен беше истинско удоволствие да бъда сред толкова много успешни
						жени и да обмена с тях енергия, опит и вдъхновение."
					/>
				</Helmet>
				<div className="singlePostDesc">
					<p>
						Скъпи приятели и последователи, повечето от вас разбраха за
						участието ми във Forbes Woman Forum, Sky is the limit, което се
						проведе на 07.11.2023г. в София, хотел Хилтън.
					</p>
					<p>
						За мен беше истинско удоволствие да бъда сред толкова много успешни
						жени и да обмена с тях енергия, опит и вдъхновение.
					</p>
					<p className="pb-10">
						Споделям с вас въпросите и отговорите от моето интервю, а скоро
						очаквам и видеото.
					</p>
					<p>
						<b>Въпрос: </b>
						<i>
							"Пословичен е фактът, че светът на финансите е доста
							по-негостоприемен към дамите, отколкото към господата. Какво Ви
							мотивира да стартирате кариерата си именно в банковия сектор?
						</i>
					</p>
					<p>
						<b>Отговор: </b>
						<i>
							Започнах работа в банковия сектор, когато бях на 23 години. От
							малка бях вдъхновена от своята леля, която е една от най –
							успешните жени банкери в България, била е на чело на не една
							банка. Именно тя е и причината още от дете да вярвам, че света на
							финансите е не по – малко подходящ и за жените.
						</i>
					</p>
					<p>
						<b>Въпрос: </b>
						<i>
							Освен във финансите, вие имате богат опит в изграждането на
							брандове и в други предизвикателни сектори. Кои са основните
							трудности, които срещнахте по пътя към успеха и как ги
							преодоляхте?
						</i>
					</p>
					<p>
						<b>Отговор: </b>
						<i>
							Живота винаги ме е поставял пред интересни предизвикателства. Едно
							от тях ме отведе в Испания, където живях 2 години със семейството
							си. Успешно развих компания за превоз на международни товари,
							дъщерна фирма на една от водещите логистични компании в Испания.
							От опит мога да кажа, че всъщност този вид дейност има много
							повече мъжка енергия в сравнение с областта на финансите.
							Предизвикателството за мен беше, че работата е предимно, да не
							кажа изцяло, с мъже и е изключително трудно този тип служители да
							допуснат дори само идеята за жената като авторитет, камо ли като
							работодател. Поради дълбоко лични причини се прибрах в България.
							Наложи се да започна кариерата си отначало, но винаги съм се
							отнасяла с огромна благодарност към наученото и опитността.
							Предприемачеството винаги е била моята сила и страст и работата в
							банковия сектор не беше моя път. Опитах да се върна в банкови
							сектор, бях мениджър на банков клон, но чувствах, че това не е
							моето място, в този период в живота ми се появи нова възможност и
							аз се гмурнах в нея. Пренасочих се в сектора на интериорния дизайн
							и мебелното производство. Успяхме да позиционираме и утвърдим на
							Българския пазар бранд, който до момента не беше познат и към
							днешна дата тази компания е водеща в производството на мебели по
							индивидуален проект, които смело мога да кажа, че не отстъпват по
							качество и визия на водещи италиански компании.
						</i>
					</p>
					<p>
						<b>Въпрос: </b>
						<i>
							Кога дойде моментът, в който усетихте, че коучингът е Вашето
							призвание?
						</i>
					</p>
					<p>
						<b>Отговор: </b>
						<i>
							През годините, постепенно чувствах, че имам необходимостта да
							разбирам повече за живота и духовния свят, за всичко онова, което
							е отвъд материята. Живота започна да ми поднася срещи с хора,
							които аз наричам мои учители. Огромното напрежение и стрес и
							фокусирането върху бизнеса донесе много негативи в личния ми
							живот. Започнах лична психотерапия, посещавах различни тренинги за
							духовно и личностно развитие. Това беше момента, в който започнах
							да обръщам повече внимание и на женската си енергия. Взех решение
							и записах магистратура по „Психологично консултиране“, без да имам
							план да практикувам. Идеята ми беше да помагам на себе си и на
							служителите в компанията, както и в провеждането на преговори,
							комуникация с клиенти. След дългия път на вътрешна трансформация и
							след като отново се случи така, че повторих същия модел на
							смесване бизнес и лични взаимоотношения в живота си, след като
							отново се наложи да започна живота си от нула, да изградя дом, да
							отглеждам децата си сама, да поставя основите на свой бизнес,
							осъзнах, че всъщност моят опит и моята сила следва бъдат споделяни
							и чрез тях да помагам на хората. Това е моето призвание. Аз се
							превърнах в учител.
						</i>
					</p>
					<p>
						<b>Въпрос: </b>
						<i>
							Как подходихте към това да се уверите, че давате най-доброто от
							себе си за Вашите клиенти?
						</i>
					</p>
					<p>
						<b>Отговор: </b>
						<i>
							Аз не си служа само с техниките на традиционната психотерапия.
							Сертифициран рейки терапевт съм, имам диплома по енергийна
							психология, йога терапия. Подхождам към клиентите си комплексно
							като се придържам към моята мантра, че добрия психолог и коуч е
							този, който е бил на дъното, в тъмното и е успявал не само да
							излезе от там, а да стане също толкова успешен във всяка сфера на
							живота си. Следователно, никога не съм се съмнявала, че бих могла
							да дам най – доброто на своите клиенти, а именно личния си опит и
							професионалния такъв.
						</i>
					</p>
					<p>
						<b>Въпрос: </b>
						<i>
							Много от нашите гости днес са успешни предприемачи, които обаче в
							качеството си на такива са напълно наясно с трудностите в началото
							на едно бизнес начинание. Как коучингът може да помогне в този
							така предизвикателен период?
						</i>
					</p>
					<p>
						<b>Отговор: </b>
						<i>
							Всеки един човек, в началото на своята кариера или при промяна на
							своя професионален път е добре да бъде подкрепен. Важно е да
							отбележа, че коучинга работи с потенциала на човека, който се
							намира вътре в него и моята задача е да му помогна да достигне
							именно до него. Да повярва във себе си, да повиши самооценката си,
							да обикне себе си. Определянето на целите, освобождаването от
							негативните убеждения, които получаваме още като деца и
							заместването им с положителни такива, правилното изграждане на
							ключови навици са в основата на успеха. Много важно е да отбележа,
							че не следва да имаме план Б! Целта е една, ние я манифестираме в
							живота, посяваме семенцето на успеха и нямаме съмнение, че то ще
							поникне. Ако и за миг покажем колебание, че то няма да покълне или
							ако не покълне, имаме резервен вариант, сигнала, който подаваме
							към духовния свят е, че това не ни е най – важното, следователно,
							то няма да се случи. От това, което чувам, ми се струва, че не
							само предприемачите могат да имат полза от коучинг.
						</i>
					</p>
					<p>
						<b>Въпрос: </b>
						<i>
							Приложим ли е енергийният коучинг в корпоративния свят и способен
							ли е да подобри мотивацията и производителността на служителите в
							отделни екипи или дори цели компании?
						</i>
					</p>
					<p>
						<b>Отговор: </b>
						<i>
							Все по – често ми се случва на корпоративните тренинги да
							забелязвам интерес от участниците към енергийните коучинг терапии.
							В днешно време вече има доста литература, много колеги правят
							различни тренинги и ретрийти, които хората посещават и започват
							своето търсене. Моят опит показва, че йога практиките често са
							първата стъпка към започването на така нареченото „търсене“.
							Именно затова аз съчетавам йога терапията с моята работа. Тялото
							ни следва да бъде в баланс, затова не можем да обръщаме внимание
							само на физическото си тяло, тоест материята. Необходимо е да
							обръщаме внимание на психологическото и емоционалното здраве,
							както и на връзката ни с духовния свят. Да имаш балансирани
							служители, търсещи и израстващи, с ясно дефинирана ценностна
							система и цели, е ключа към успеха на една компания. Тук се
							намесва коучинга и по – специално енергийния коучинг. Има
							изключително добри лидер шип ментори и треньори, но не е добре да
							обръщаме внимание само на ума и професионалните умения на
							служителите.
						</i>
					</p>
				</div>
			</div>
			<div className="iframeWrapper">
				<iframe
					width="560"
					height="315"
					src="https://www.youtube.com/embed/gz4tVhg2zzI?si=EFk6abDNRU94HN-l"
					title="YouTube video player"
					frameborder="0"
					allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
					allowFullScreen
				></iframe>
			</div>
		</div>
	);
}
