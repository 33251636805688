import "../styles/posts.css";
import image from "../../../assets/images/stanislava-leadership.jpg";
import ContactForm from "../../contact-form/contactForm";
import { Helmet } from "react-helmet";

export default function LeadershipCoaching() {
	return (
		<div className="singlePost">
			<div className="singlePostWrapper">
				<img src={image} alt="Leadership coaching" className="singlePostImg" />
				<h1 className="singlePostTitle">Leadership коучинг сесия</h1>
				<Helmet>
					<title>Leadership коучинг сесия</title>
					<meta
						name="description"
						content="По време на One on one коучинг помагам на клиента да разгърне
						потенциала си и да постигне професионалните си цели. Индивидуалният
						коучинг може да има различни акценти, но в случая с лидерския
						коучинг той е предназначен да помогне на хората да станат по-добри
						лидери. Той прави това, като разглежда техните силни и слаби страни
						насърчавайки само рефлексията. С това по-задълбочено себеразбиране и
						моята помощ ще можете да взимате по-добри решения, да мотивирате
						другите, да подобрите своите комуникационни умения и да станете
						по-добър лидер."
					/>
				</Helmet>
				<div className="singlePostDesc">
					<p>
						One on one коучинг по лидерство може да бъде трансформиращо за
						участниците и техните организации. Може да помогне на хората да
						развият нови прозрения, да трансформират начина, по който мислят
						относно ролята им и да им помогне да развият нови умения. На свой
						ред представянето им на работното място може да бъде катализатор за
						растеж и развитие в техните екипи и в по-широката организация.
					</p>
					<p className="bold-text mt-mb-10">
						Какво точно представлява индивидуалният коучинг, какво включва и
						защо е толкова ефективен?
					</p>
					<p className="mt-mb-10">Какво е значението на One on one коучинг?</p>
					<p>
						По време на One on one коучинг помагам на клиента да разгърне
						потенциала си и да постигне професионалните си цели. Индивидуалният
						коучинг може да има различни акценти, но в случая с лидерския
						коучинг той е предназначен да помогне на хората да станат по-добри
						лидери. Той прави това, като разглежда техните силни и слаби страни
						насърчавайки само рефлексията. С това по-задълбочено себеразбиране и
						моята помощ ще можете да взимате по-добри решения, да мотивирате
						другите, да подобрите своите комуникационни умения и да станете
						по-добър лидер.
					</p>
					<p className="bold-text mt-mb-10">
						Как провеждате индивидуална коучинг сесия?
					</p>
					<p>
						В миналото индивидуалните коучинг сесии се провеждаха предимно лице
						в лице, като коуч и клиент бяха в една и съща стая. С развитието на
						цифровите технологии вече е възможно коучингът да се предоставя от
						разстояние. Коучингът може да се състои от Zoom срещи, телефонен
						коучинг и лице в лице, отразявайки конкретните нужди на клиента и
						неговата компания.
					</p>
					<p>
						По време на коучинг сесия, активно изслушвам участника, осигурявайки
						му безопасна и подкрепяща среда, в която да говори открито и честно.
					</p>
					<p>
						Предоставям обратна връзка, помагайки на участника да обмисли
						по-добре собствените си силни и слаби страни.
					</p>
					<p>
						Задавайки целенасочени въпроси ще съумявате ефективно да оцените
						представянето си. Заедно ще идентифицираме ключови умения, върху
						които трябва да работим, ще разработим план за постигане на
						подобренията в представянето ви, като предварително сме определили
						целите.
					</p>
					<p>
						Процесът на обучение може да бъде интензивен и изисква упорита
						работа, но наградите в края на процеса са значителни. Това е
						персонализирана форма на професионално и личностно развитие, която в
						крайна сметка може да бъде трансформираща за вас.
					</p>
					<p className="bold-text mt-mb-10">
						Какви са ползите от индивидуалния коучинг?
					</p>
					<p>
						One on One коучинг е един от най-ефективните начини, чрез който
						хората могат да подобрят представянето си на работното място. Това е
						интензивен, персонализиран начин, за помощ на хората при
						идентифицирането на техните силни и слаби страни, да развият своите
						основни лидерски способности и да подобрят своите комуникационни
						умения.
					</p>
					<p>
						Клиентите ми достигат нови висоти в кариерата си, като същевременно
						са по-щастливи и по-пълноценни в живота си.
					</p>
				</div>
			</div>
			<div className="contact-form-container">
				<h3>Изпратете запитване</h3>
				<ContactForm />
			</div>
		</div>
	);
}
