import "../styles/posts.css";
import image from "../../../assets/images/soul-detective.jpg";
import sdwCertificate from "../../../assets/docs/sdw/certificate-sdw.png";
import ContactForm from "../../contact-form/contactForm";
import { Helmet } from "react-helmet";

export default function SoulDetective() {
	return (
		<div className="singlePost">
			<div className="singlePostWrapper">
				<img src={image} alt="taro" className="singlePostImg img-width-auto" />
				<h1 className="singlePostTitle">
					Soul Detective Work
					{/* <div className="singlePostEdit">
                        <i className="singlePostIcon fa-regular fa-pen-to-square "></i>
                        <i className="singlePostIcon far fa-trash-alt"></i>
                    </div> */}
				</h1>
				<Helmet>
					<title>Soul Detective Work</title>
					<meta
						name="description"
						content="Повечето трансформативни, изцелителни и
						психотерапевтични методи и школи работят само с
						емоционалните, менталните и евентуално с кинестетичните
						пластове на човешката същност. Методите на Енергийната
						Психология работят и с енергийната система на човек, но
						целите на повечето от тези методи са насочени единствено
						към актуалното физиологично, емоционално и енергийно
						състояние на човека, като работят ограничавайки се в
						биографичното ниво на личността."
					/>
				</Helmet>
				{/* <div className="singlePostInfo">
                    <span className="singlePostAuthor">
                        Author: <b>Safak</b>
                    </span>
                    <span className="singlePostDate">1 hour ago</span>
                </div> */}
				<div className="singlePostDesc">
					<p>Какво представлява метода SDW?</p>
					<p>
						Повечето трансформативни, изцелителни и психотерапевтични методи и
						школи работят само с емоционалните, менталните и евентуално с
						кинестетичните пластове на човешката същност. Методите на
						Енергийната Психология работят и с енергийната система на човек, но
						целите на повечето от тези методи са насочени единствено към
						актуалното физиологично, емоционално и енергийно състояние на
						човека, като работят ограничавайки се в биографичното ниво на
						личността.
					</p>
					<p>
						SDW отива отвъд тези пластове, адресирайки работата си към
						трансперсоналните и трансбиографични енергийни отпечатъци в душата
						на човека. Голяма част от актуалните емоционални, телесни и житейски
						проблеми имат като първоизточник (невидими корени) подсъзнателните
						програми и енергийни „отпечатъци“, които са формирани в някоя от
						предишните ни реинкарнации или са свързани с модел наследен от
						предците ни, с деструктивни енергийни въздействия от личен или
						външен източник, обети за бедност и безбрачие, дадени в минал живот,
						както и други видове енергийно-информационни конфигурации, които
						блокират нормалният поток на енергията.
					</p>
					<p>
						Когато успеем да се освободим от тези блокажи, това освобождава
						свободния поток на енергията, което от своя страна води след себе си
						като резултат до това да живеем живота си здрави, да имаме
						удовлетворяващи взаимоотношения, да имаме енергията да постигаме
						целите в живота си и да еволюираме в духовен план.
					</p>
					<p>Минали животи</p>
					<p>
						Квантовата физика ни показа и ни доказа, че на определено квантово
						ниво така наречената от физиците „нулева точка“, времето и
						пространството не съществуват. На това ниво всичко е СЕГА и ТУК.
						Погледнато от перспективата на доказателствата на квантовата физика,
						които ни показват, че в основата на всичко лежи една изначална
						реалност, в която няма времеви и пространствени измерения (което
						означава, че всичко съществува във всеки един момент и навсякъде),
						миналите животи са нещо, което съществува като енергия и информация
						във всеки един момент на съществуването ни в триизмерното
						пространство на проявеният свят и по тази причина е разбираемо, че
						те оказват влияние върху актуалният ни живот. Така наречената от
						квантовите физици „нулева точка“, по начина по който те я описват,
						кореспондира напълно с това, което в различните култури и времена
						бива наричано с различни имена – Дао, Брахман, Бог, Абсолют,
						Източник и т.н.
					</p>
					<p>
						Изцелението на проблемите останали от минали животи, позволява на
						човек да продължи напред в живота си, освободен от приковаването си
						към тях.
					</p>
					<p>
						SDW, създадена от Д-р Стоун, работи ползвайки мускулното тестване
						(приложна кинезиология) с цел да достигнем до информацията от минали
						животи и прибягва до техники от Енергийната психология, с помощта на
						които бързо се изчистват травми от минали реинкарнации,
						възстановявайки загубената енергия.
					</p>
					<p>
						SDW може да бъде ефективен метод при следните проблеми: Зависимости
						към храна, цигари, алкохол, и наркотици, при гняв, тревожност и
						панически атаки, нощно напикаване при деца, депресия и тъга, печал,
						дислексия, вина, срам, паразитни навици, инсомния, болезнени
						спомени, кошмари, посттравматичен стрес, страхове и фобии
						включително страх от говорене пред публика, страх от височини, страх
						от летене със самолет и др. Алергии към различни субстанции, както и
						такива с неизяснена етиология, дихателни дисфункции, справяне с
						болков синдром, артрит, мигрена, болки в гърба и врата и др.
					</p>
				</div>
			</div>
			<div className="contact-form-container">
				<h3>Изпратете запитване</h3>
				<ContactForm />
			</div>
			<div className="certificates-container">
				<img
					src={sdwCertificate}
					alt="sdw-certificate"
					className="certificateImg"
				/>
			</div>
		</div>
	);
}
