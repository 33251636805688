import "../styles/posts.css";
import image from "../../../assets/images/reiki.jpg";
import reikiCertificate_1 from "../../../assets/docs/reiki/certificate-reiki-1.png";
import reikiCertificate_2 from "../../../assets/docs/reiki/certificate-reiki-2.png";
import reikiCertificate_3 from "../../../assets/docs/reiki/certificate-reiki-3.png";
import reikiCertificate_4 from "../../../assets/docs/reiki/certificate-reiki-4.png";
import ContactForm from "../../contact-form/contactForm";
import { Helmet } from "react-helmet";

export default function Reiki() {
	return (
		<div className="singlePost">
			<div className="singlePostWrapper">
				<img src={image} alt="reiki" className="singlePostImg img-width-auto" />
				<h1 className="singlePostTitle">
					Рейки терапия
					{/* <div className="singlePostEdit">
                        <i className="singlePostIcon fa-regular fa-pen-to-square "></i>
                        <i className="singlePostIcon far fa-trash-alt"></i>
                    </div> */}
				</h1>
				<Helmet>
					<title>Рейки терапия</title>
					<meta
						name="description"
						content="В хода на консултативния процес, според нуждата на
						клиента, прилагам терапия с рейки. Това помага за
						правилното протичане на енергията през тялото. Когато тя
						не протича правилно, не достига до всяка част от него,
						ние сме блокирани на психическо, емоционално и физическо
						ниво."
					/>
				</Helmet>
				{/* <div className="singlePostInfo">
                    <span className="singlePostAuthor">
                        Author: <b>Safak</b>
                    </span>
                    <span className="singlePostDate">1 hour ago</span>
                </div> */}
				<div className="singlePostDesc">
					<p>
						В хода на консултативния процес, според нуждата на клиента, прилагам
						терапия с рейки. Това помага за правилното протичане на енергията
						през тялото. Когато тя не протича правилно, не достига до всяка част
						от него, ние сме блокирани на психическо, емоционално и физическо
						ниво.
					</p>
					<p>
						Какво представлява Рейки? Думата рейки има японски произход и се
						превежда като Универсална, Космическа или Божествена жизнена
						енергия. Рейки на японски се изписва с два йероглифа – Рей и Ки. Рей
						означава свещен, дух, мистерия, универсален, божествен, космически.
						А йероглифът Ки означава енергия, природна картина, талант, чувство.
						Ки е жизнената енергия, която тече във всичко живо и без която
						животът е невъзможен. Тя се проявява както в хората, така и в
						животните, растенията и минералите. Присъства във всичко около нас,
						дори в неодушевените предмети. Рейки идва директно от Източника,
						Създателя или Висшия Разум и се прилага върху всичко одушевено и
						неодушевено. Тъй като е енергия, вибрираща с висока честота, не се
						вижда с просто око, но може да бъде регистрирана с Кирлианова
						фотография.
					</p>
					<p>
						Всеки човек и всяко живо същество притежава канал, през който
						протича жизнената енергия. Но поради неправилния ни начин на живот
						нашия канал е толкова затлачен, че енергията тече съвсем слабо през
						него и ние често оставаме без жизнени сили. За да бъдем добри
						проводници, е необходимо да пречистим и разширим този канал и то
						така, че не само да получаваме достатъчно енергия, но и да можем да
						отдаваме на всички, които имат нужда да подобряват енергийното си
						състояние.
					</p>
				</div>
			</div>
			<div className="contact-form-container">
				<h3>Изпратете запитване</h3>
				<ContactForm />
			</div>
			<div className="certificates-container">
				<img
					src={reikiCertificate_1}
					alt="reiki-certificate"
					className="certificateImg"
				/>
				<img
					src={reikiCertificate_2}
					alt="reiki-certificate"
					className="certificateImg"
				/>
				<img
					src={reikiCertificate_3}
					alt="reiki-certificate"
					className="certificateImg"
				/>
				<img
					src={reikiCertificate_4}
					alt="reiki-certificate"
					className="certificateImg"
				/>
			</div>
		</div>
	);
}
