import "../styles/posts.css";
import image from "../../../assets/images/company-consulting.jpg";
import ContactForm from "../../contact-form/contactForm";
import { Helmet } from "react-helmet";

export default function CompanyConsulting() {

	return (
		<div className="singlePost">
			<div className="singlePostWrapper">
				<img
					src={image}
					alt="company consulting"
					className="singlePostImg img-height-500"
				/>
				<h1 className="singlePostTitle">
					Корпоративно консултиране
				</h1>
				<Helmet>
					<title>Корпоративно консултиране</title>
					<meta name="description" content="Корпоративното консултиране е предназначен да помогне на
						работодателите да постигнат лична и екипна ефективност
						на работното място, да оценят и подобрят индивидуалния
						стил на работа на служителите. Освен това подобрява
						комуникативните умения и взаимоотношенията в работната
						среда и у дома."/>
				</Helmet>
				<div className="singlePostDesc">
					<p>
						Лидерите в света на бизнеса откриват колко полезно може
						да бъде правилното корпоративно психологично
						консултиране за производителността на бизнеса. Също така
						е от полза за живота и благосъстоянието на техните
						служители. Създаването на компания, пълна със силни,
						здрави лидери, които работят добре заедно, е пътят към
						бизнес успеха. Това, от гледна точка на служителя,
						означава компания, в която той се чувства добре.
					</p>
					<p>Какво е корпоративно консултиране?</p>
					<p>
						Този тип консултиране е предназначен да помогне на
						работодателите да постигнат лична и екипна ефективност
						на работното място, да оценят и подобрят индивидуалния
						стил на работа на служителите. Освен това подобрява
						комуникативните умения и взаимоотношенията в работната
						среда и у дома.
					</p>
					<p>
						Моята услуга „Психотерапия за позитивен живот“, която
						прилагам в корпоративно консултиране подобрява
						представянето на вашите служителите на всяко ниво чрез
						четири фокус области:
					</p>
					<ul>
						<li className="tick-shaped">Психологично консултиране</li>
						<li className="tick-shaped">Учене и развитие</li>
						<li className="tick-shaped">Коучинг</li>
						<li className="tick-shaped">Лично емоционално и психическо здраве</li>
						<li className="tick-shaped">Превенция от бърнаут</li>
					</ul>
					<p>
						Защо да изберете „Психотерапия за позитивен живот“ за
						вашата компания?
					</p>
					<p>
						Аз ви предлагам професионални консултантски услуги,
						които помагат на служителите да намерят и използват
						правилните инструменти, за да станат по-добри в
						изпълнението на своите ежедневни задачи. Нейният
						уникален подход съчетава традиционната психотерапия със
						силата на позитивната психология, коучинга, практики от
						енергийната психология, спорт и изграждане на правилни
						хранителни навици. Това дава възможност на клиентите да
						направят здравословни промени в живота, необходими за
						насърчаване на динамично професионално и лично
						израстване.
					</p>
					<p>
						Възможността да предлагате консултантски услуги чрез
						вашата компания означава, че служителите ще имат достъп
						до персонализирани услуги и стратегии за консултиране,
						които ще им помогнат при преодоляването на уникални
						предизвикателства на работното място и личния живот. И
						резултатът? По-добре работещ персонал и по-положителна
						организационна култура. Увеличете максимално
						производителността на най-големия си актив.
					</p>
					<p>
						Вашите служители са най-големият актив на вашата
						компания. Следователно „Психотерапията за позитивния
						живот“ е тук, за да гарантира, че максимизирате този
						актив. Така че чрез моите услуги, ще можете да
						предложите на служителите си инструментите, от които се
						нуждаят, за да реализират пълния си потенциал в работен
						и личен план. Накратко, когато вашите служители се
						чувстват свободни и работят в пълния си потенциал,
						вашата компания ще реализира ръст в своето развитие.
					</p>
					<p>
						Свържете се с мен, за да научите повече на моите
						корпоративни консултантски услуги.
					</p>
				</div>
			</div>
			<div className="contact-form-container">
				<h3>Изпратете запитване</h3>
				<ContactForm />
			</div>
		</div>
	);
}
