import "./home.css";
import backgroundImage from "../../assets/images/header-background.jpg";
// import aboutMeImg from "../../assets/images/mandala.jpg";
// import aboutMeImg1 from "../../assets/images/stanislava-home-page1.jpg";
import aboutMeImg from "../../assets/images/stanislava-home-page2.jpg";
import leadsImg from "../../assets/images/stanislava-leads-img.jpg";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import Reviews from "../../components/reviews/Reviews";

export default function Header() {
	const [showHeader, setShowHeader] = useState(false); // State to control header content visibility
	useEffect(() => {
		// Show header content after a short delay to give the fade-in effect
		const timer = setTimeout(() => {
			setShowHeader(true);
		}, 500); // Adjust the delay duration as needed

		return () => clearTimeout(timer);
	}, []);
	return (
		<>
			<div className="header-section">
				<img
					className="header-background"
					src={backgroundImage}
					alt="header-background-img"
				/>
				<div
					className={`header-content ${showHeader ? "fadeIn show" : "fadeIn"}`}
				>
					<h2>Коучинг, бизнес коучинг, енергийни коучинг сесии</h2>
				</div>
			</div>
			<div className="aboutMe">
				<div className="aboutMeContainer">
					<div className="contentWrapper">
						<h2 className="centered">Добре дошли в моето пространство!</h2>
						<div className="card-container">
							<div className="card">
								<div className="card-body p-5">
									<div className="author-image-container d-flex justify-content-center mb-4">
										<img
											src={leadsImg}
											className="rounded-circle author-img"
											alt="Author"
										/>
									</div>
									<figure className="text-center mb-0">
										<blockquote className="blockquote mb-4">
											<p>
												<>
													“Вярвам, че пътя на промяната в живота ви е промяната
													на вашата енергия. Когато движите енергията през
													сърцето, то трансформира негативните мисли,
													безпокойството, съмнението и страха, в нов начин на
													съществуване.
													Отваряйки сърцето си, създавате верига от движение и
													поток.”
												</>
											</p>
										</blockquote>
										<figcaption className="blockquote-footer mb-0">
											Станислава Митова
										</figcaption>
									</figure>
								</div>
							</div>
						</div>
						<p>
							Благодаря ви, че отделяте от времето си, за да го посетите и
							вярвам, че тук ще направите първата крачка към положителната
							промяна в живота си!
						</p>
						<p>Казвам се Станислава Митова и съм психолог и енергиен коуч.</p>
						<p>
							Последните няколко години посветих времето си на това да развивам
							и помагам на себе си, за да мога днес да предам своята опитност и
							професионализъм на други жени и мъже, помагайки им да намерят пътя
							към тяхната идентичност, свобода и любов към себе си. Да се
							доверят на живота. Бих се радвала да виждам повече истински дишащи
							и живеещи хора, които не са в плен на негативните утвърждения,
							които получават още с раждането си, хора които приемат родителите
							си в сърцата си и не са роби на страховете си.
						</p>
						<p>Очаквам ви с много трепет и вълнение!</p>
						<Link className="learnMoreButton" to="/about">
							Научете повече
						</Link>
					</div>
					{/* <img
						src={aboutMeImg}
						alt="mandala"
						className="aboutMeImg"
					/> */}

					<div className="aboutMeImagesContainer">
						{/* <img src={aboutMeImg1} alt="about-me-img-1" className="aboutMeImg" /> */}
						<img src={aboutMeImg} alt="about-me-img-2" className="aboutMeImg" />
					</div>
				</div>
			</div>
			<Reviews />
		</>
	);
}
